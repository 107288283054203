import {PartnershipPlayerDto} from "../dtos/partnership.dto";

export type MatchStatus = 'InProgress' | 'Completed' | 'Cancelled';
export type MatchType = 'Practice' | 'Scrimmage' | 'Tournament' | 'Other';

export interface MatchPartnershipInfoDto {
  partnershipId: string;
  playerOne: PartnershipPlayerDto;
  playerTwo: PartnershipPlayerDto;
}

export interface MatchSetInfoDto {
  setId: string;
  status: 'InProgress' | 'Completed' | 'Cancelled';
  homeScore: number;
  awayScore: number;
  winningPartnershipId?: string;
}

export interface MatchInfoDto {
  matchId: string;
  tenantId: number;
  season: string;
  homePartnership: MatchPartnershipInfoDto;
  awayPartnership: MatchPartnershipInfoDto;
  sets: MatchSetInfoDto[];
  type: MatchType;
  status: MatchStatus;
  winningPartnershipId?: string;
  location?: string;
  date: string;
  createdAt: string;
  createdBy: number;
}

export type AddMatchDto = Omit<MatchInfoDto, 'tenantId' | 'matchId' | 'createdAt' | 'createdBy'>;

//TODO: Move this and other types to the DTO folder
export const validateAddMatchDto = (dto: Partial<AddMatchDto>): string | null  => {
  const errors: string[] = [];

  // Validate homePartnership
  if (!dto.homePartnership) {
    errors.push('homePartnership is required.');
  } else {
    if (typeof dto.season !== 'string') {
      errors.push('season is required.');
    }
    if (typeof dto.homePartnership.partnershipId !== 'string') {
      errors.push('homePartnership partnershipId must be a string.');
    }
    if (typeof dto.homePartnership.playerOne.firstName !== 'string') {
      errors.push('homePartnership playerOne firstName must be a string.');
    }
    if (typeof dto.homePartnership.playerOne.lastName !== 'string') {
      errors.push('homePartnership playerOne lastName must be a string.');
    }
    if (typeof dto.homePartnership.playerTwo.firstName !== 'string') {
      errors.push('homePartnership playerTwo firstName must be a string.');
    }
    if (typeof dto.homePartnership.playerTwo.lastName !== 'string') {
      errors.push('homePartnership playerTwo lastName must be a string.');
    }
  }

  // Validate awayPartnership
  if (!dto.awayPartnership) {
    errors.push('awayPartnership is required.');
  } else {
    if (typeof dto.awayPartnership.partnershipId !== 'string') {
      errors.push('awayPartnership partnershipId must be a string.');
    }
    if (typeof dto.awayPartnership.playerOne.firstName !== 'string') {
      errors.push('awayPartnership playerOne firstName must be a string.');
    }
    if (typeof dto.awayPartnership.playerOne.lastName !== 'string') {
      errors.push('awayPartnership playerOne lastName must be a string.');
    }
    if (typeof dto.awayPartnership.playerTwo.firstName !== 'string') {
      errors.push('awayPartnership playerTwo firstName must be a string.');
    }
    if (typeof dto.awayPartnership.playerTwo.lastName !== 'string') {
      errors.push('awayPartnership playerTwo lastName must be a string.');
    }
  }

  // Validate sets
  if (!Array.isArray(dto.sets)) {
    errors.push('Sets array is required');
  } else {
    dto.sets.forEach((set, index) => {
      if (typeof set.setId !== 'string') {
        errors.push(`Set ${index + 1}: setId must be a string.`);
      }
      if (typeof set.homeScore !== 'number') {
        errors.push(`Set ${index + 1}: homeScore must be a number.`);
      }
      if (typeof set.awayScore !== 'number') {
        errors.push(`Set ${index + 1}: awayScore must be a number.`);
      }
      if (['InProgress', 'Completed', 'Cancelled'].indexOf(set.status) < 0) {
        errors.push(`Set ${index + 1}: status must be one of 'InProgress', 'Completed', or 'Cancelled'.`);
      }
      if (set.winningPartnershipId && typeof set.winningPartnershipId !== 'string') {
        errors.push(`Set ${index + 1}: winningPartnershipId must be a string if present.`);
      }
    });
  }

  // Validate type
  if (['Practice', 'Scrimmage', 'Tournament', 'Other'].indexOf(dto.type as string) < 0) {
    errors.push('type must be one of "Practice", "Scrimmage", "Tournament", or "Other".');
  }

  // Validate status
  if (['InProgress', 'Completed', 'Cancelled'].indexOf(dto.status as string) < 0) {
    errors.push('status must be one of "InProgress", "Completed", or "Cancelled".');
  }

  // Validate date
  if (typeof dto.date !== 'string') {
    errors.push('date must be a string.');
  }

  // Validate optional fields
  if (dto.location && typeof dto.location !== 'string') {
    errors.push('location must be a string if present.');
  }

  if (dto.winningPartnershipId && typeof dto.winningPartnershipId !== 'string') {
    errors.push('winningPartnershipId must be a string if present.');
  }

  return errors.length > 0 ? errors.join(' ') : null;
};