import React, {useState} from "react";
import {
  Box,
  Button, Card, CardContent,
  FormControl, GlobalStyles,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent, ToggleButton, ToggleButtonGroup,
  Typography, useTheme
} from '@mui/material';
import {Add, Check, Settings, SportsVolleyball, SwapVert, Undo} from "@mui/icons-material";
import {MatchInfoDto, MatchSetInfoDto} from "sandy-shared/dist/types/match-info.type";
import {PlayerWithSideInfo} from "sandy-shared/dist/types/player-info.type";
import {StattedPointWithUiInfo} from "../../../shared/utility/statted-point-helpers";
import {PartnershipWithState} from "../MatchStattingPage-types";
import './MatchStattingLeftSidebar.css';

export interface MatchStattingLeftSidebarHandlers {
  handleSelectedSetChange: (event: SelectChangeEvent<string>) => void;
  handleServerPlayerIdChange: (playerId: string) => void;
  handleUndoClick: () => void;
  handleCompleteSetClick: () => void;
  handleStartNewSetClick: () => void;
  handleSwapCourtSidesClick: () => void;
  handleSettingsClick: () => void;
  handleHistoricalPointClick: (point: StattedPointWithUiInfo) => void;
}

export interface MatchStattingLeftSidebarData {
  match: MatchInfoDto;
  players: PlayerWithSideInfo[];
  fullPointHistory: StattedPointWithUiInfo[];
  homePartnership: PartnershipWithState;
  selectedSet: MatchSetInfoDto;
  selectedPointId: string;
}

export interface MatchStattingLeftSidebarProps {
  data: MatchStattingLeftSidebarData;
  handlers: MatchStattingLeftSidebarHandlers;
  canUndo: () => boolean;
  canCompleteSet: () => boolean;
}

export const MatchStattingLeftSidebar = (props: MatchStattingLeftSidebarProps) => {
  const theme = useTheme();
  const {data, handlers, canUndo, canCompleteSet} = props;
  const {match, players, fullPointHistory, homePartnership, selectedSet, selectedPointId} = data;
  const {handleSelectedSetChange, handleServerPlayerIdChange, handleUndoClick, handleCompleteSetClick, handleStartNewSetClick, handleSwapCourtSidesClick, handleSettingsClick, handleHistoricalPointClick} = handlers;

  const [historyOrControls, setHistoryOrControls] = useState<'history' | 'controls'>('controls');
  const [chooseServerMenuAnchorEl, setChooseServerMenuAnchorEl] = React.useState<null | HTMLElement>(null);


  const handleHistoryOrControlsToggle = (
    event: React.MouseEvent<HTMLElement>,
    historyOrControls: string,
  ) => {
    if(historyOrControls === null) return;
    setHistoryOrControls(historyOrControls as 'history' | 'controls');
  };

  const getSetSelectText = (set: MatchSetInfoDto) => {
    const setNumber = (match?.sets?.findIndex(s => s.setId === set.setId) ?? 0) + 1;
    return `#${setNumber} (${set.status})`
  }

  const handleSetServerButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setChooseServerMenuAnchorEl(event.currentTarget);
  };

  const handleSetServerPlayerClick = (playerId: string) => {
    handleServerPlayerIdChange(playerId);
    setChooseServerMenuAnchorEl(null);
  }

  const handleSetServerMenuClose = () => {
    setChooseServerMenuAnchorEl(null);
  }

  return (
    <Box className={'match-statting-left-sidebar-container'}>
      <Box style={{display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', paddingBottom: '10px'}}>
        <Button onClick={handleUndoClick} disabled={!canUndo()} variant={'contained'} startIcon={<Undo/>}
                style={{width: '100%'}}>Undo</Button>
      </Box>
      <ToggleButtonGroup color="primary" value={historyOrControls} exclusive onChange={handleHistoryOrControlsToggle}>
        <ToggleButton value="controls" style={{fontSize: '12px'}}>Controls</ToggleButton>
        <ToggleButton value="history" style={{fontSize: '12px'}}>History</ToggleButton>
      </ToggleButtonGroup>
      <Box style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        border: '1px solid grey',
        borderRadius: '5px',
        height: '100%',
        maxHeight: '580px',
        width: '100%'
      }}>
        {historyOrControls === 'controls' ?
          <>
            <Box style={{display: 'flex', flexDirection: 'column', padding: '10px'}}>
              <FormControl fullWidth>
                <InputLabel>Set</InputLabel>
                <Select
                  style={{marginBottom: '10px', fontSize: '14px'}}
                  label="Set"
                  value={getSetSelectText(selectedSet!)}
                  onChange={e => handleSelectedSetChange(e)}>
                  {match?.sets.map((set) => (
                    <MenuItem key={set.setId} value={getSetSelectText(set)} style={{fontSize: '12px'}}>
                      {getSetSelectText(set)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <Button variant={'contained'} disabled={!canCompleteSet()} onClick={() => handleCompleteSetClick()}
                        style={{width: '100%'}} startIcon={<Check/>}>Complete Set</Button>
                <Button variant={'contained'} onClick={() => handleStartNewSetClick()} style={{width: '100%'}}
                        startIcon={<Add/>}>Start New Set</Button>
              </Box>
            </Box>
            <Box style={{display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px'}}>
              <Button onClick={handleSetServerButtonClick} variant={'contained'} style={{width: '100%'}}
                      startIcon={<SportsVolleyball/>}>Change Server</Button>
              <Menu anchorEl={chooseServerMenuAnchorEl} open={!!chooseServerMenuAnchorEl}
                    onClose={handleSetServerMenuClose}>
                {players.map(player =>
                  <MenuItem
                    key={player.playerId}
                    onClick={() => handleSetServerPlayerClick(player.playerId)}>{player.firstName} {player.lastName}</MenuItem>
                )}
              </Menu>
              <Button onClick={handleSwapCourtSidesClick} variant={'contained'} style={{width: '100%'}} startIcon={<SwapVert/>}>
                Flip Team Sides
              </Button>
            </Box>
            <Box style={{display: 'flex', flexGrow: 1, flexDirection: 'column', gap: '10px', padding: '10px'}}>
              <Button onClick={handleSettingsClick} variant={'contained'} style={{width: '100%'}} startIcon={<Settings/>}>
                Settings
              </Button>
            </Box>
          </> :
          <>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
              }}
            >
              {fullPointHistory.map((point, index) => {
                const isActive = point.pointId === selectedPointId;
                const backgroundColor = isActive ? theme.palette.action.selected : theme.palette.background.paper;
                const winningPartnership = point.winningPartnershipId === homePartnership!.partnershipId ? 'H' : 'A';
                return (
                  <Box key={index} style={{display: 'flex', padding: '5px', width: '100%'}}>
                    <Box
                      className="click-effect"
                      onClick={() => handleHistoricalPointClick(point)} // Replace with your click handler function
                      style={{
                        display: 'flex',
                        padding: '5px',
                        backgroundColor: backgroundColor, // Use theme background
                        boxShadow: theme.shadows[1], // Raised effect from theme shadows
                        borderRadius: theme.shape.borderRadius, // Theme border radius
                        cursor: 'pointer',
                        transition: 'transform 0.2s, background-color 0.2s',
                        width: '100%'

                      } as any}
                    >
                      <Typography variant="body2" color="text.primary" style={{fontSize: '12px'}}>
                        {`${winningPartnership} (${point.uiInfo.homeScore}-${point.uiInfo.awayScore}) ${point.uiInfo.summary}`}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </>}
      </Box>
    </Box>
  )
}