import {Box, IconButton, Typography, Menu, MenuItem} from "@mui/material";
import PlayerCard from "../../../components/PlayerStatsCard";
import {SwapHoriz} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import './MatchPartnershipContainer.css';
import {PartnershipWithState} from "../MatchStattingPage-types";
import {CourtSide} from "sandy-shared/dist/types/court-info.type";
import {StattedPointWithUiInfo} from "../../../shared/utility/statted-point-helpers";
import {createEmptyPlayerStats, PlayerWithSideInfo} from "sandy-shared/dist/types/player-info.type";
import {PlayerInfoDto, PlayerStatsDto} from "sandy-shared/dist/dtos/player.dto";
import {calculatePlayersStatsFromPoints} from "sandy-shared/dist/analysis/analysis-helpers";
import {ChangePlayerModal} from "./ChangePlayerModal";
import {getOrAddPartnership} from "../../../services/partnership.service";
import {AddPartnershipDto} from "sandy-shared/dist/dtos/partnership.dto";

export interface MatchPartnershipContainerHandlers {
  handleUpdatePartnership: (partnership: PartnershipWithState) => void;
  handleSetServer: (playerId: string) => void;
}

export interface MatchPartnershipContainerData {
  isTopPartnership: boolean;
  partnership: PartnershipWithState;
  opponentPartnership: PartnershipWithState;
  pointHistory: StattedPointWithUiInfo[];
  servingPlayerId: string | undefined;
}

export interface MatchPartnershipContainerProps {
  handlers: MatchPartnershipContainerHandlers;
  data: MatchPartnershipContainerData;
}

export const MatchPartnershipContainer = (props: MatchPartnershipContainerProps) => {
  const {handlers, data} = props;
  const {handleUpdatePartnership, handleSetServer} = handlers;
  const {partnership, opponentPartnership, pointHistory, servingPlayerId} = data;
  const [playerMenuAnchorEl, setPlayerMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [playerMenuPosition, setPlayerMenuPosition] = useState<{ top: number, left: number } | null>(null);
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerWithSideInfo | null>(null);
  const [changePlayerIsOpen, setChangePlayerIsOpen] = useState(false);

  const getPartnershipLeftSidePlayer = (partnership: PartnershipWithState) => partnership.playerOne.side === CourtSide.Left ? partnership.playerOne : partnership.playerTwo;
  const getPartnershipRightSidePlayer = (partnership: PartnershipWithState) => partnership.playerOne.side === CourtSide.Right ? partnership.playerOne : partnership.playerTwo;
  const getPartnershipVisualLeftPlayer = (partnership: PartnershipWithState) => data.isTopPartnership ? getPartnershipRightSidePlayer(partnership) : getPartnershipLeftSidePlayer(partnership);
  const getPartnershipVisualRightPlayer = (partnership: PartnershipWithState) => data.isTopPartnership ? getPartnershipLeftSidePlayer(partnership) : getPartnershipRightSidePlayer(partnership);

  const playerIsServing = (player: PlayerWithSideInfo) => servingPlayerId === player.playerId;
  const playerStats = (player: PlayerWithSideInfo): PlayerStatsDto => {
    return calculatePlayersStatsFromPoints(pointHistory, [player.playerId], partnership.partnershipId);
  }

  const switchPlayerSides = () => {
    const updatedPartnership = {
      ...partnership,
      playerOne: {
        ...partnership.playerOne,
        side: partnership.playerOne.side === CourtSide.Left ? CourtSide.Right : CourtSide.Left
      },
      playerTwo: {
        ...partnership.playerTwo,
        side: partnership.playerTwo.side === CourtSide.Left ? CourtSide.Right : CourtSide.Left
      }
    };
    handleUpdatePartnership(updatedPartnership);
  }

  const handlePlayerCardClick = (event: React.MouseEvent<HTMLElement>, player: PlayerWithSideInfo) => {
    setPlayerMenuAnchorEl(event.currentTarget);
    setPlayerMenuPosition({top: event.clientY, left: event.clientX});
    setSelectedPlayer(player);
  };

  const handleMenuClose = () => {
    setPlayerMenuAnchorEl(null);
    setPlayerMenuPosition(null);
  };

  const handleSetServerClick = () => {
    selectedPlayer?.playerId && handleSetServer(selectedPlayer.playerId);
    handleMenuClose();
  };

  const handleChangePlayerClick = () => {
    setChangePlayerIsOpen(true);
    handleMenuClose();
  };

  const handleChangePlayerModalClose = () => {
    setChangePlayerIsOpen(false);
  }

  const getChangePlayerIgnorePlayerIds = () => {
    const opponentPlayerIds = [opponentPartnership.playerOne.playerId, opponentPartnership.playerTwo.playerId];
    const partnerId = selectedPlayer?.playerId === getPartnershipLeftSidePlayer(partnership).playerId ? getPartnershipRightSidePlayer(partnership).playerId : getPartnershipLeftSidePlayer(partnership).playerId;
    return [...opponentPlayerIds, partnerId]
  }

  const handleChangePlayerModalSave = async (player: PlayerInfoDto) => {
    if(selectedPlayer!.playerId === player.playerId) {
      handleChangePlayerModalClose();
      return;
    }

    const replacedPlayerOne = partnership.playerOne.playerId === selectedPlayer?.playerId;
    const newPartnershipArgs: AddPartnershipDto = {
      playerOneId: replacedPlayerOne ? player.playerId : partnership.playerOne.playerId,
      playerTwoId: !replacedPlayerOne ? player.playerId : partnership.playerTwo.playerId
    }
    const newPartnership = await getOrAddPartnership(newPartnershipArgs)
    const newPartnershipWithState: PartnershipWithState = {
      partnershipId: newPartnership.partnershipId,
      playerOne: {
        ...newPartnership.playerOne,
        side: partnership.playerOne.side
      },
      playerTwo: {
        ...newPartnership.playerTwo,
        side: partnership.playerTwo.side
      },
      score: partnership.score
    }
    handleUpdatePartnership(newPartnershipWithState);
    handleChangePlayerModalClose();
  }

  return (
    <Box className={'player-container-partnership-container'}>
      <ChangePlayerModal
        open={changePlayerIsOpen}
        onClose={handleChangePlayerModalClose}
        selectedPlayerId={selectedPlayer?.playerId!}
        ignorePlayerIds={getChangePlayerIgnorePlayerIds()}
        onSave={player => handleChangePlayerModalSave(player)}/>
      <PlayerCard
        playerInfo={getPartnershipVisualLeftPlayer(partnership)}
        playerStats={playerStats(getPartnershipVisualLeftPlayer(partnership))}
        isServing={playerIsServing(getPartnershipVisualLeftPlayer(partnership))}
        onClick={(event) => handlePlayerCardClick(event, getPartnershipVisualLeftPlayer(partnership))}
      />
      <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '60px'}}>
          <Typography variant={"h3"}>{partnership.score}</Typography>
        </div>
        <IconButton style={{marginBottom: '8px', cursor: 'pointer', transform: 'scale(1.5)'}} onClick={switchPlayerSides}><SwapHoriz/></IconButton>
      </div>
      <PlayerCard
        playerInfo={getPartnershipVisualRightPlayer(partnership)}
        playerStats={playerStats(getPartnershipVisualRightPlayer(partnership))}
        isServing={playerIsServing(getPartnershipVisualRightPlayer(partnership))}
        onClick={(event) => handlePlayerCardClick(event, getPartnershipVisualRightPlayer(partnership))}
      />
      <Menu
        anchorEl={playerMenuAnchorEl}
        open={Boolean(playerMenuAnchorEl)}
        onClose={handleMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={playerMenuPosition ? { top: playerMenuPosition.top, left: playerMenuPosition.left } : undefined}
      >
        <MenuItem onClick={handleSetServerClick}>Set Server</MenuItem>
        <MenuItem onClick={handleChangePlayerClick}>Change Player</MenuItem>
      </Menu>
    </Box>
  )
}

export default MatchPartnershipContainer;