
import React, {useEffect, useState} from 'react';
import {
  Box,
  Button, Card, CardContent,
  FormControl, GlobalStyles,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent, ToggleButton, ToggleButtonGroup,
  Typography, useTheme
} from '@mui/material';
import './MatchStattingPage.css';
import PlayerCard from "../../components/PlayerStatsCard";
import {
  acePoint, attackInNetPoint,
  attackKillPoint,
  attackOutPoint,
  blockCoveredTransitionPoint,
  blockKillPoint,
  faultPoint, serveInNetPoint,
  serveOutPoint,
  dugTransitionPoint, freeBallTransitionPoint, serveFaultPoint, toolKillPoint
} from "../../services/statting.service";
import {deletePoint, getPoints, savePoint} from "../../services/point.service";
import {createEmptyPlayerStats, PlayerWithSideInfo} from "sandy-shared/dist/types/player-info.type";
import {StattedPoint} from "sandy-shared/dist/types/statted-point.type";
import {
  isErrorServe,
  ServeInPlayLocation,
  ServeOutLocation,
  ServeSideLocation,
  ServiceOutcome
} from "sandy-shared/dist/types/servce-info.type";
import {
  AttackLocation,
  AttackOutcome,
  AttackOutLocation,
  AttackSubLocation,
  AttackType, isAttackWithoutAttackType, isAttackWithoutAttackTypeOrLocation, isAttackWithOutLocation,
  isAttackWithoutShotLocation, isAttackWithShotLocation,
  ShotLocation
} from "sandy-shared/dist/types/attack-info.type";
import {CourtSide} from "sandy-shared/dist/types/court-info.type";
import { PlayerStatsDto } from 'sandy-shared/dist/dtos/player.dto';
import {useParams} from "react-router-dom";
import {completeSet, getMatch, startNewSet, updateMatch} from "../../services/match.service";
import {MatchInfoDto, MatchSetInfoDto} from "sandy-shared/dist/types/match-info.type";
import {enqueueSnackbar} from "notistack";
import {StattedPointDto} from "sandy-shared/dist/dtos/statted-point.dto";
import {CompleteSetDto} from "sandy-shared/dist/dtos/match-actions.dto";
import {calculatePlayersStatsFromPoints} from "sandy-shared/dist/analysis/analysis-helpers";
import {getAttackLocation, getServeInPlayLocation, getShotLocation} from "../../shared/utility/shared-court-actions";
import {CurrentPointState, EphemeralPointState, PartnershipWithState, PlayerStatsWithId} from "./MatchStattingPage-types";
import {assertAllDefined} from "sandy-shared/dist/utils/misc-utils";
import Court, {CourtProps} from "../../components/Court";
import {StattedPointWithUiInfo, addUiInfoToPoints} from "../../shared/utility/statted-point-helpers";
import {Season} from "sandy-shared/dist/types/season-info.type";
import {MatchStattingLeftSidebar, MatchStattingLeftSidebarProps} from "./components/MatchStattingLeftSidebar";
import MatchStattingSettingsModal, {MatchStattingSettings} from "./components/MatchStattingSettingsModal";
import MatchPartnershipContainer, {MatchPartnershipContainerProps} from "./components/MatchPartnershipContainer";

const sandActiveColor = '#c2b280';
const sectionSelectedColor = '#1565c0';
const defaultSwitchTotal = 7;

const defaultSettings: MatchStattingSettings = { autoSetServer: true, autoSwapSides: true, keepScore: true };
const practiceSetting: MatchStattingSettings = { autoSetServer: false, autoSwapSides: false, keepScore: false };

const MatchStattingPage = () => {
  const theme = useTheme();

  const routeParams = useParams<Record<string, string>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const matchId = routeParams["matchId"]!;
  const [match, setMatch] = useState<MatchInfoDto>();
  const [selectedSetId, setSelectedSetId] = useState<string>();

  const [currentPointState, setCurrentPointState] = useState<CurrentPointState>(CurrentPointState.PendingServe);
  const [homePartnership, setHomePartnership] = useState<PartnershipWithState>();
  const [awayPartnership, setAwayPartnership] = useState<PartnershipWithState>();
  const [initialTopPartnershipId, setInitialTopPartnershipId] = useState<string>();

  const [servingPlayerId, setServingPlayerId] = useState<string>();
  const [topPartnershipId, setTopPartnershipId] = useState<string>();
  const [allPlayerStats, setAllPlayerStats] = useState<PlayerStatsWithId[]>([]);
  const [fullPointHistory, setFullPointHistory] = useState<StattedPointWithUiInfo[]>([]);
  const [pointHistory, setPointHistory] = useState<StattedPointWithUiInfo[]>([]);
  const [selectedPointId, setSelectedPointId] = useState<string | null>(null);
  const [selectedPointResult, setSelectedPointResult] = useState<string | null>(null);

  const [selectedServeInPlayLocation, setSelectedServeInPlayLocation] = useState<ServeInPlayLocation | undefined>(undefined);
  const [selectedAttackLocation, setSelectedAttackLocation] = useState<AttackLocation | undefined>(undefined);
  const [selectedAttackSubLocation, setSelectedAttackSubLocation] = useState<AttackSubLocation | undefined>(undefined);
  const [selectedShotLocation, setSelectedShotLocation] = useState<ShotLocation | undefined>(undefined);
  const [selectedAttackType, setSelectedAttackType] = useState<AttackType | undefined>(undefined);
  const [selectedAttackOutLocation, setSelectedAttackOutLocation] = useState<AttackOutLocation | undefined>(undefined);
  const [selectedServeOutLocation, setSelectedServeOutLocation] = useState<ServeOutLocation | undefined>(undefined);
  const [undoActionStack, setUndoActionStack] = React.useState<(() => void)[]>([])
  const [showServeReceiveLocationsOverride, setShowServeReceiveLocationsOverride] = useState<boolean | undefined>(undefined);
  const [showAttackLocationsOverride, setShowAttackLocationsOverride] = useState<boolean | undefined>(undefined);

  const [matchStattingSettings, setMatchStattingSettings] = useState<MatchStattingSettings>(defaultSettings);
  const [matchStattingSettingsModalOpen, setMatchStattingSettingsModalOpen] = useState<boolean>(false);

  const servingPartnership = [homePartnership?.playerOne?.playerId, homePartnership?.playerTwo?.playerId].includes(servingPlayerId) ? homePartnership : awayPartnership;
  const receivingPartnership = servingPartnership?.partnershipId === homePartnership?.partnershipId ? awayPartnership : homePartnership;
  const topPartnership = homePartnership?.partnershipId === topPartnershipId ? homePartnership : awayPartnership;
  const bottomPartnership = homePartnership?.partnershipId === topPartnershipId ? awayPartnership : homePartnership;
  const courtIsRotated = servingPartnership?.partnershipId === bottomPartnership?.partnershipId;
  const selectedSet = match?.sets?.find(s => s.setId === selectedSetId);

  const mostRecentPartnershipServer = (partnershipId: string, points: StattedPoint[]) => {
    const reversedPoints = [...points].reverse();
    const lastServicePoint = reversedPoints.find(p => p.servingPartnershipId === partnershipId);
    return lastServicePoint?.serverPlayerId;
  }

  const nextServerId = (partnership: PartnershipWithState, points: StattedPoint[]) => {
    const lastServer = mostRecentPartnershipServer(partnership.partnershipId, points);
    return lastServer === partnership.playerOne.playerId ? partnership.playerTwo.playerId : partnership.playerOne.playerId;
  }

  const getPartnershipLeftPlayer = (partnership: PartnershipWithState) => partnership.playerOne.side === CourtSide.Left ? partnership.playerOne : partnership.playerTwo;
  const getPartnershipRightPlayer = (partnership: PartnershipWithState) => partnership.playerOne.side === CourtSide.Right ? partnership.playerOne : partnership.playerTwo;
  const getPartnershipName = (partnership: PartnershipWithState) => `${partnership.playerOne.lastName}/${partnership.playerTwo.lastName}`;
  const playerIsServing = (player: PlayerWithSideInfo) => servingPlayerId === player.playerId;
  const playerStats = (player: PlayerWithSideInfo): PlayerStatsDto => {
    if(!homePartnership || !awayPartnership) {
      return createEmptyPlayerStats();
    }
    const playerPartnership = player.playerId === homePartnership.playerOne.playerId || player.playerId === homePartnership.playerTwo.playerId ? homePartnership : awayPartnership;
    return calculatePlayersStatsFromPoints(pointHistory, [player.playerId], playerPartnership.partnershipId);
  }

  const fetchAndUpdateSetPointHistory = (season: string, setId: string) => {
    getPoints(season, setId)
      .then(points => {
        setFullPointHistory(addUiInfoToPoints(points, homePartnership!.partnershipId));
      })
      .catch(e => {
        console.error('Failed to get points:', e);
        enqueueSnackbar('Failed to get points', {variant: 'error', autoHideDuration: 5000});
      })
  }

  //region State update wrappers
  const updateServingPartnershipState = (state: PartnershipWithState | undefined) => {
    if(servingPartnership?.partnershipId === homePartnership?.partnershipId) {
      console.log('Updating Serving Partnership: HOME')
      setHomePartnership(state);
    } else {
      console.log('Updating Serving Partnership: AWAY')
      setAwayPartnership(state);
    }
  }

  const updateReceivingPartnershipState = (state: PartnershipWithState | undefined) => {
    if(receivingPartnership?.partnershipId === homePartnership?.partnershipId) {
      console.log('Updating Receiving Partnership: HOME')
      setHomePartnership(state);
    } else {
      console.log('Updating Receiving Partnership: AWAY')
      setAwayPartnership(state);
    }
  }

  const updateTopOrBottomPartnershipState = async (isTop: boolean, state: PartnershipWithState | undefined) => {
    const targetPartnership = isTop ? topPartnership : bottomPartnership;
    const targetPartnershipIsHome = targetPartnership?.partnershipId === homePartnership?.partnershipId;
    const isNewPartnership = state && state?.partnershipId !== targetPartnership?.partnershipId; // This happens when a player is swapped mid-match (usually in practice mode)
    if(isNewPartnership) {
      await updateMatch(matchId, targetPartnershipIsHome ? {homePartnership: state} : {awayPartnership: state});
      if(initialTopPartnershipId === targetPartnership?.partnershipId) {
        setInitialTopPartnershipId(state.partnershipId);
      }
      if(topPartnershipId === targetPartnership?.partnershipId) {
        setTopPartnershipId(state.partnershipId);
      }
    }
    if(targetPartnership) {
      setHomePartnership(state);
    } else {
      setAwayPartnership(state);
    }
  }
  //endregion

  //region UseEffect hooks
  useEffect(() => {
    setIsLoading(true);
    getMatch(matchId).then(match => {
      console.log('Got Match:', match);
      setMatch(match);
      setSelectedSetId(match.sets[0].setId);
      const homePartnership =  {
        ...match.homePartnership,
        playerOne: {...match.homePartnership.playerOne, side: CourtSide.Left},
        playerTwo: {...match.homePartnership.playerTwo, side: CourtSide.Right},
        score: 0
      }
      const awayPartnership =  {
        ...match.awayPartnership,
        playerOne: {...match.awayPartnership.playerOne, side: CourtSide.Left},
        playerTwo: {...match.awayPartnership.playerTwo, side: CourtSide.Right},
        score: 0
      }
      setHomePartnership(homePartnership);
      setAwayPartnership(awayPartnership);
      setTopPartnershipId(homePartnership.partnershipId);
      setInitialTopPartnershipId(homePartnership.partnershipId);
      setAllPlayerStats([
        {playerId: homePartnership.playerOne.playerId, ...createEmptyPlayerStats()},
        {playerId: homePartnership.playerTwo.playerId, ...createEmptyPlayerStats()},
        {playerId: awayPartnership.playerOne.playerId, ...createEmptyPlayerStats()},
        {playerId: awayPartnership.playerTwo.playerId, ...createEmptyPlayerStats()}
      ]);
      setServingPlayerId(homePartnership.playerOne.playerId);
      setMatchStattingSettings(match.type === 'Practice' ? practiceSetting : defaultSettings);
    })
    .catch(e => {
      console.error('Failed to get match:', e);
      enqueueSnackbar('Failed to get match', {variant: 'error', autoHideDuration: 5000});
    })
    .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    console.log(`Home partnership: ${homePartnership?.partnershipId}: ${homePartnership?.score}`);
    console.log(`Away partnership: ${awayPartnership?.partnershipId}: ${awayPartnership?.score}`);
  }, [homePartnership, awayPartnership]);

  useEffect(() => {
    if(!selectedSetId || !match?.season) return;
    fetchAndUpdateSetPointHistory(match.season, selectedSetId);
  }, [selectedSetId, match]);

  useEffect(() => {
    const lastPoint = pointHistory.length > 0 ? pointHistory[pointHistory.length - 1] : null;

    const servingPartnershipScore = matchStattingSettings.keepScore
      ? pointHistory.filter(p => p.winningPartnershipId === servingPartnership!.partnershipId).length
      : 0;
    const receivingPartnershipScore = matchStattingSettings.keepScore
      ? pointHistory.filter(p => p.winningPartnershipId === receivingPartnership!.partnershipId).length
      : 0;
    console.log('Last Point:', JSON.stringify(lastPoint));

    updateServingPartnershipState({...servingPartnership!, score: servingPartnershipScore});
    updateReceivingPartnershipState({...receivingPartnership!, score: receivingPartnershipScore});

    if(!lastPoint) return;

    // If we have a selected point, we want to see who was serving for that point, in normal operation we want to see who is serving for the _next_ point
    if(selectedPointId !== null) {
      setServingPlayerId(lastPoint.serverPlayerId);
    } else {
      if(lastPoint.winningPartnershipId !== servingPartnership!.partnershipId && matchStattingSettings.autoSetServer) {
        const newServerId = nextServerId(receivingPartnership!, pointHistory);
        setServingPlayerId(newServerId);
      }
    }
    const playerAndPartnershipIds = [
      {playerId: homePartnership!.playerOne.playerId, partnershipId: homePartnership!.partnershipId},
      {playerId: homePartnership!.playerTwo.playerId, partnershipId: homePartnership!.partnershipId},
      {playerId: awayPartnership!.playerOne.playerId, partnershipId: awayPartnership!.partnershipId},
      {playerId: awayPartnership!.playerTwo.playerId, partnershipId: awayPartnership!.partnershipId}
    ];
    const allPlayerStats = playerAndPartnershipIds.map(pap => ({playerId: pap.playerId, ...calculatePlayersStatsFromPoints(pointHistory, [pap.playerId], pap.partnershipId)}));
    setAllPlayerStats(allPlayerStats);
  }, [pointHistory]);

  // We do this outside of the main point history update to make sure that all the state is updated before we check for a switch
  useEffect(() => {
    const switchTotal = matchStattingSettings.autoSwapSides ? defaultSwitchTotal : 9999999;
    const scoreTotal = (topPartnership?.score ?? 0) + (bottomPartnership?.score ?? 0);
    const moddedPoints = scoreTotal % (switchTotal * 2);
    if(scoreTotal === 0 && topPartnership?.partnershipId !== initialTopPartnershipId) { // This happens when initialTopPartnershipId is changed by a manual court side swap
      setTopPartnershipId(initialTopPartnershipId)
    } else if(scoreTotal > 0 && moddedPoints >= switchTotal && topPartnership?.partnershipId === initialTopPartnershipId) {
      setTopPartnershipId(bottomPartnership?.partnershipId);
    } else if (scoreTotal > 0 && moddedPoints < switchTotal && topPartnership?.partnershipId !== initialTopPartnershipId) {
      setTopPartnershipId(bottomPartnership?.partnershipId);
    }
  }, [homePartnership, awayPartnership, initialTopPartnershipId]);

  useEffect(() => {
    if(selectedPointId === null) {
      setPointHistory(fullPointHistory)
      return;
    }
    const points = []
    for (const point of fullPointHistory) {
      points.push(point)
      if(point.pointId === selectedPointId) {
        break;
      }
    }

    setPointHistory(points);
    displayPointOnCourt(points[points.length - 1]);
  }, [selectedPointId]);

  // This prevents any desync if a point is added while we're viewing history (which shouldn't happen, but just in case)
  useEffect(() => {
    setPointHistory(fullPointHistory)
  }, [fullPointHistory]);
  //endregion

  const displayPointOnCourt = (point: StattedPointDto)=> {
    setSelectedPointResult('Unknown')
    setCurrentPointState(CurrentPointState.AttackOutOfPlay); // This disables all court buttons
    setShowAttackLocationsOverride(false);
    setShowServeReceiveLocationsOverride(true);

    if(isErrorServe(point.serveInfo)) {
      const result = {summary: 'Missed Serve', detail: `Missed Serve: ${point.serveInfo.outLocation}`};
      setSelectedServeOutLocation(point.serveInfo.outLocation);
      setSelectedPointResult(result.detail)
      return result;
    }
    setSelectedServeInPlayLocation(point.serveInfo.inPlayLocation);

    if(point.serveInfo.outcome === ServiceOutcome.Ace) {
      const result = {summary: 'Ace', detail: `Ace: ${point.serveInfo.inPlayLocation.side}, ${point.serveInfo.inPlayLocation.location}`};
      setSelectedPointResult(result.detail)
      return result;
    }

    if(!point.attackInfo) {
      console.error('Point has serve in place but no attack info:', point);
      return;
    }
    setShowAttackLocationsOverride(true);
    setShowServeReceiveLocationsOverride(false);

    if(isAttackWithoutAttackTypeOrLocation(point.attackInfo)) {
      const result = {summary: 'Transition', detail: 'Free Ball'};
      setCurrentPointState(CurrentPointState.FreeBallTransition); // This is how the highlighting is done for free balls, since it isn't an attack location
      setSelectedPointResult(result.detail)
      return result;
    }

    setSelectedAttackLocation(point.attackInfo.attackLocation);
    setSelectedAttackSubLocation(point.attackInfo.attackSubLocation);

    if(isAttackWithoutShotLocation(point.attackInfo)) {
      const result = {summary: point.attackInfo.outcome, detail: point.attackInfo.outcome};
      setSelectedPointResult(result.detail)
      return result;
    }

    if(isAttackWithShotLocation(point.attackInfo)) {
      const result = {summary: `${point.attackInfo.outcome} (${point.attackInfo.attackType})`, detail: `${point.attackInfo.outcome} (${point.attackInfo.attackType}): ${point.attackInfo.shotLocation}`};
      setSelectedShotLocation(point.attackInfo.shotLocation);
      setSelectedPointResult(result.detail)
      return result;
    }

    if(isAttackWithOutLocation(point.attackInfo)) {
      const result = {summary: `${point.attackInfo.outcome} (${point.attackInfo.attackType})`, detail: `${point.attackInfo.outcome} (${point.attackInfo.attackType}): ${point.attackInfo.outLocation}`};
      setSelectedAttackOutLocation(point.attackInfo.outLocation);
      setSelectedPointResult(result.detail)
      return result;
    }

    if(isAttackWithoutAttackType(point.attackInfo)) {
      const result = {summary: point.attackInfo.outcome, detail: point.attackInfo.outcome};
      setSelectedPointResult(result.detail)
      return result;
    }
  }

  //region Styling
  const shotLocationSectionStyle = (rowIndex: number, colIndex: number) => {
    const shotLocation = getShotLocation(rowIndex, colIndex);
    return {backgroundColor: selectedShotLocation === shotLocation ? sectionSelectedColor : undefined}
  }

  const attackOnLocationSectionStyle = (attackLocationIndex: number) => {
    const attackLocation = getAttackLocation(attackLocationIndex);
    return {backgroundColor: selectedAttackLocation === attackLocation && selectedAttackSubLocation === AttackSubLocation.On ? sectionSelectedColor : undefined}
  };

  const attackOffLocationSectionStyle = (attackLocationIndex: number) => {
    const attackLocation = getAttackLocation(attackLocationIndex);
    return {backgroundColor: selectedAttackLocation === attackLocation && selectedAttackSubLocation === AttackSubLocation.Off ? sectionSelectedColor : undefined}
  };

  const freeBallLocationSectionStyle = () => {
    return {backgroundColor: currentPointState === CurrentPointState.FreeBallTransition ? sectionSelectedColor : undefined}
  };

  const serveReceiveSectionStyle = (rowIndex: number, colIndex: number) => {
    const serveReceiveLocation = getServeInPlayLocation(rowIndex, colIndex);
    return {backgroundColor: selectedServeInPlayLocation?.location === serveReceiveLocation.location && selectedServeInPlayLocation.side === serveReceiveLocation.side ? sectionSelectedColor : undefined}
  }

  const attackOutOfBoundsLocationStyle = (location: AttackOutLocation) => ({
    backgroundColor: location === selectedAttackOutLocation ? '#1565c0' : undefined
  });

  const serveOutOfBoundsLocationStyle = (location: ServeOutLocation) => ({
    backgroundColor: location === selectedServeOutLocation ? '#1565c0' : undefined
  });

  const receivingOutOfBoundsAdditionalClasses = () => currentPointState === CurrentPointState.PendingServe ? 'clickable-court-section' : undefined
  const receivingInBoundsAdditionalClasses = () => currentPointState === CurrentPointState.PendingServe ? 'clickable-court-section' : undefined
  const attackLocationOrFreeAdditionalClasses = () => currentPointState === CurrentPointState.ServeInPlay ? 'clickable-court-section' : undefined
  const shotLocationAdditionalClasses = () => currentPointState === CurrentPointState.PendingAttack ? 'clickable-court-section' : undefined
  const attackingOutOfBoundsAdditionalClasses = () => currentPointState === CurrentPointState.PendingAttack ? 'clickable-court-section' : undefined
  const showAttackLocations = () => currentPointState !== CurrentPointState.PendingServe
  const showServeReceiveLocation = () => currentPointState === CurrentPointState.PendingServe
  //endregion

  //region Undo
  const resetEphemeralPointState = () => {
    const previousEphemeralPointState: EphemeralPointState = {
      serveInPlayLocation: selectedServeInPlayLocation,
      shotLocation: selectedShotLocation,
      attackLocation: selectedAttackLocation,
      attackSubLocation: selectedAttackSubLocation,
      attackOutLocation: selectedAttackOutLocation,
      attackType: selectedAttackType,
      currentPointState: currentPointState
    };

    setSelectedServeInPlayLocation(undefined);
    setSelectedShotLocation(undefined)
    setSelectedAttackLocation(undefined);
    setSelectedAttackSubLocation(undefined);
    setSelectedAttackOutLocation(undefined);
    setSelectedAttackType(undefined);
    setSelectedServeOutLocation(undefined);
    setShowAttackLocationsOverride(undefined);
    setShowServeReceiveLocationsOverride(undefined);
    setCurrentPointState(CurrentPointState.PendingServe);
    return previousEphemeralPointState;
  }

  const setEphemeralPointState = (state: EphemeralPointState) => {
    setSelectedServeInPlayLocation(state.serveInPlayLocation);
    setSelectedShotLocation(state.shotLocation);
    setSelectedAttackLocation(state.attackLocation);
    setSelectedAttackSubLocation(state.attackSubLocation);
    setSelectedAttackOutLocation(state.attackOutLocation);
    setSelectedAttackType(state.attackType);
    setCurrentPointState(state.currentPointState);
  }

  const pushUndoAction = (action: () => void) => {
    setUndoActionStack(stack => [...stack, action]);
  }

  const popUndoAction = () => {
    const undoAction = undoActionStack[undoActionStack.length - 1];
    setUndoActionStack(stack => stack.slice(0, stack.length - 1));
    return undoAction;
  }

  const handleUndoClick = () => {
    if(undoActionStack.length === 0) {
      return
    }
    const undoAction = popUndoAction();
    undoAction();
  }

  const canUndo = () => undoActionStack.length > 0;
  //endregion

  //region Intermediate statting state handlers
  const assertPointState = (forState: string, expectedState: CurrentPointState) => {
    if(currentPointState !== expectedState) {
      console.log(`Invalid state for ${forState}, expected ${expectedState}, but was ${currentPointState}`);
      return false;
    }
    return true;
  }

  const handleServeReceiveSectionClick = (rowIndex: number, colIndex: number) => {
    if(!assertPointState('handleServeReceiveSectionClick', CurrentPointState.PendingServe)) return;

    setCurrentPointState(CurrentPointState.ServeInPlay);
    const location = getServeInPlayLocation(rowIndex, colIndex);
    setSelectedServeInPlayLocation(location);

    pushUndoAction(() => {
      setSelectedServeInPlayLocation(undefined)
      setCurrentPointState(CurrentPointState.PendingServe);
    })
  };

  const handleAttackOnLocationClick = (locationIndex: number) => {
    if(!assertPointState('handleAttackLocationClick', CurrentPointState.ServeInPlay)) return;

    const attackLocation = getAttackLocation(locationIndex);
    setSelectedAttackLocation(attackLocation);
    setSelectedAttackSubLocation(AttackSubLocation.On)
    setCurrentPointState(CurrentPointState.PendingAttack);

    pushUndoAction(() => {
      setSelectedAttackLocation(undefined);
      setSelectedAttackSubLocation(undefined);
      setCurrentPointState(CurrentPointState.ServeInPlay);
    })
  }

  const handleAttackOffLocationClick = (locationIndex: number) => {
    if(!assertPointState('handleAttackOffLocationClick', CurrentPointState.ServeInPlay)) return;

    const attackLocation = getAttackLocation(locationIndex);
    setSelectedAttackLocation(attackLocation);
    setSelectedAttackSubLocation(AttackSubLocation.Off)
    setCurrentPointState(CurrentPointState.PendingAttack);

    pushUndoAction(() => {
      setSelectedAttackLocation(undefined);
      setSelectedAttackSubLocation(undefined);
      setCurrentPointState(CurrentPointState.ServeInPlay);
    })
  }

  const handleShotLocationSectionClick = (rowIndex: number, colIndex: number) => {
    if(!assertPointState('handleShotLocationSectionClick', CurrentPointState.PendingAttack)) return;

    const shotLocation = getShotLocation(rowIndex, colIndex);
    setSelectedShotLocation(shotLocation);
    setCurrentPointState(CurrentPointState.AttackInPlay)

    pushUndoAction(() => {
      setSelectedShotLocation(undefined);
      setCurrentPointState(CurrentPointState.PendingAttack);
    })
  };

  const handleAttackDig = (attackType: AttackType) => {
    if(!assertPointState('handleAttackDig', CurrentPointState.AttackInPlay)) return;

    setSelectedAttackType(attackType);
    setCurrentPointState(CurrentPointState.DigTransition);

    pushUndoAction(() => {
      setSelectedAttackType(undefined);
      setCurrentPointState(CurrentPointState.AttackInPlay);
    })
  }

  const handleAttackOutError = (outLocation: AttackOutLocation) => {
    if(!assertPointState('handleAttackOutError', CurrentPointState.PendingAttack)) return;

    setSelectedAttackOutLocation(outLocation);
    setCurrentPointState(CurrentPointState.AttackOutOfPlay)

    pushUndoAction(() => {
      setSelectedAttackOutLocation(undefined);
      setCurrentPointState(CurrentPointState.PendingAttack);
    })
  }

  const handleBlockRecycle = (attackType: AttackType) => {
    if(!assertPointState('handleBlockRecycle', CurrentPointState.PendingAttack)) return;

    setSelectedAttackType(attackType);
    setCurrentPointState(CurrentPointState.BlockTransition);

    pushUndoAction(() => {
      setSelectedAttackType(undefined);
      setCurrentPointState(CurrentPointState.PendingAttack);
    })
  }

  const handleFreeBallClick = () => {
    if(!assertPointState('handleFreeBallClick', CurrentPointState.ServeInPlay)) return;

    setCurrentPointState(CurrentPointState.FreeBallTransition);

    pushUndoAction(() => {
      setCurrentPointState(CurrentPointState.ServeInPlay);
    })
  }
  //endregion

  //region Terminal statting state handlers
  const addPointAndReset = (point: StattedPoint) => {
    const pointToSave = {
      ...point,
      season: Season.Spring2025, // TODO - this shouldn't be hardcoded
      setId: selectedSetId!,
    }
    savePoint(pointToSave)
      .then((savedPoint) => {
        setFullPointHistory(addUiInfoToPoints([...fullPointHistory, savedPoint], homePartnership!.partnershipId));
        const previousPointState = resetEphemeralPointState();
        setUndoActionStack(stack => [...stack, () => {
          if(window.confirm('Delete most recently saved point?')) {
            deletePoint(savedPoint.pointId)
              .then(() => fetchAndUpdateSetPointHistory(match?.season!, selectedSetId!))
              .then(() => setEphemeralPointState(previousPointState))
          }
        }])
      })
      .catch(e => {
        console.error('Failed to save point:', e);
        enqueueSnackbar('Failed to save point', {variant: 'error', autoHideDuration: 5000});
      })
  }

  const handleOutOfBoundsServe = (serveOutLocation: ServeOutLocation) => {
    if(!assertPointState('handleOutOfBoundsServe', CurrentPointState.PendingServe)) return;
    assertAllDefined('handleOutOfBoundsServe', servingPartnership, receivingPartnership, servingPlayerId)

    const point = serveOutPoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!, serveOutLocation);
    addPointAndReset(point);
  }

  const handleServeInNet = () => {
    if(!assertPointState('handleServeInNet', CurrentPointState.PendingServe)) return;
    assertAllDefined('handleServeInNet', servingPartnership, receivingPartnership, servingPlayerId);

    const point = serveInNetPoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!);
    addPointAndReset(point);
  }

  const handleServeFault = () => {
    if(!assertPointState('handleServeFault', CurrentPointState.PendingServe)) return;
    assertAllDefined('handleServeFault', servingPartnership, receivingPartnership, servingPlayerId);

    const point = serveFaultPoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!);
    addPointAndReset(point);
  }

  const handleAce = () => {
    if(!assertPointState('handleAce', CurrentPointState.ServeInPlay)) return;
    assertAllDefined('handleAce', servingPartnership, receivingPartnership, servingPlayerId, selectedServeInPlayLocation);

    const point = acePoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!, selectedServeInPlayLocation!);
    addPointAndReset(point);
  }

  const handleBlockKill = (attackType: AttackType) => {
    if(!assertPointState('handleBlockKill', CurrentPointState.PendingAttack)) return;
    assertAllDefined('handleBlockKill', servingPartnership, receivingPartnership, servingPlayerId, selectedServeInPlayLocation, selectedAttackLocation, selectedAttackSubLocation)
    const point = blockKillPoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!, selectedServeInPlayLocation!, attackType, selectedAttackLocation!, selectedAttackSubLocation!);
    addPointAndReset(point);
  }

  const handleToolKill = () => {
    if(!assertPointState('handleToolKill', CurrentPointState.PendingAttack)) return;
    assertAllDefined('handleToolKill', servingPartnership, receivingPartnership, servingPlayerId, selectedServeInPlayLocation, selectedAttackLocation, selectedAttackSubLocation)

    const point = toolKillPoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!, selectedServeInPlayLocation!, selectedAttackLocation!, selectedAttackSubLocation!);
    addPointAndReset(point);
  }

  const handleAttackKill = (attackType: AttackType) => {
    if(!assertPointState('handleAttackKill', CurrentPointState.AttackInPlay)) return;
    assertAllDefined('handleAttackKill', servingPartnership, receivingPartnership, servingPlayerId, selectedServeInPlayLocation, selectedAttackLocation, selectedAttackSubLocation, selectedShotLocation)

    const point = attackKillPoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!, selectedServeInPlayLocation!, attackType, selectedAttackLocation!, selectedAttackSubLocation!, selectedShotLocation!);
    addPointAndReset(point);
  }

  const handleTransitionPoint = (winningPartnershipId: string) => {
    if(currentPointState === CurrentPointState.DigTransition) {
      handleDigTransitionPoint(winningPartnershipId);
    } else if(currentPointState === CurrentPointState.BlockTransition) {
      handleBlockTransitionPoint(winningPartnershipId);
    } else if(currentPointState === CurrentPointState.FreeBallTransition) {
      handleFreeBallTransitionPoint(winningPartnershipId);
    }
    else {
      console.error('Invalid state for handleTransitionPoint, expected DigTransition, BlockTransition, or FreeBallTransition but was', currentPointState);
      throw new Error('Invalid state for handleTransitionPoint, expected DigTransition, BlockTransition, FreeBallTransition');
    }
  }

  const handleDigTransitionPoint = (winningPartnershipId: string) => {
    if(!assertPointState('handleTransitionPoint', CurrentPointState.DigTransition)) return;
    assertAllDefined('handleTransitionPoint', servingPartnership, receivingPartnership, servingPlayerId, selectedServeInPlayLocation, selectedAttackType, selectedAttackLocation, selectedAttackSubLocation, selectedShotLocation)

    const point = dugTransitionPoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!, selectedServeInPlayLocation!, selectedAttackType!, selectedAttackLocation!, selectedAttackSubLocation!, selectedShotLocation!, winningPartnershipId);
    addPointAndReset(point);
  }

  const handleBlockTransitionPoint = (winningPartnershipId: string) => {
    if(!assertPointState('handleBlockTransitionPoint', CurrentPointState.BlockTransition)) return;
    assertAllDefined('handleBlockTransitionPoint', servingPartnership, receivingPartnership, servingPlayerId, selectedServeInPlayLocation, selectedAttackType, selectedAttackLocation, selectedAttackSubLocation)

    const point = blockCoveredTransitionPoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!, selectedServeInPlayLocation!, selectedAttackType!, selectedAttackLocation!, selectedAttackSubLocation!, winningPartnershipId);
    addPointAndReset(point);
  }

  const handleFreeBallTransitionPoint = (winningPartnershipId: string) => {
    if(!assertPointState('handleFreeBallTransitionPoint', CurrentPointState.FreeBallTransition)) return;
    assertAllDefined('handleFreeBallTransitionPoint', servingPartnership, receivingPartnership, servingPlayerId, selectedServeInPlayLocation)

    const point = freeBallTransitionPoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!, selectedServeInPlayLocation!, winningPartnershipId);
    addPointAndReset(point);
  }

  const handleFaultPoint = (winningPartnershipId: string) => {
    if(!assertPointState('handleFaultPoint', CurrentPointState.PendingAttack)) return;
    assertAllDefined('handleFaultPoint', servingPartnership, receivingPartnership, servingPlayerId, selectedServeInPlayLocation, selectedAttackLocation, selectedAttackSubLocation);

    const point = faultPoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!, selectedServeInPlayLocation!, selectedAttackLocation!, selectedAttackSubLocation!, winningPartnershipId);
    addPointAndReset(point);
  }

  const handleAttackInNet = (attackType: AttackType) => {
    if(!assertPointState('handleAttackInNet', CurrentPointState.PendingAttack)) return;
    assertAllDefined('attack in net', servingPartnership, receivingPartnership, servingPlayerId, selectedServeInPlayLocation, selectedAttackLocation, selectedAttackSubLocation);

    const point = attackInNetPoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!, selectedServeInPlayLocation!, attackType, selectedAttackLocation!, selectedAttackSubLocation!);
    addPointAndReset(point);
  }

  const handleAttackOutFinished = (attackType: AttackType) => {
    if(!assertPointState('handleAttackOutFinished', CurrentPointState.AttackOutOfPlay)) return;
    assertAllDefined('attack out finished', servingPartnership, receivingPartnership, servingPlayerId, selectedServeInPlayLocation, selectedAttackLocation, selectedAttackSubLocation, selectedAttackOutLocation);

    const point = attackOutPoint(pointHistory.length, servingPartnership!, receivingPartnership!, servingPlayerId!, selectedServeInPlayLocation!, attackType, selectedAttackLocation!, selectedAttackSubLocation!, selectedAttackOutLocation!);
    addPointAndReset(point);
  }
  //endregion

  //region Set management
  const handleCompleteSetClick = async () => {
    if(!homePartnership || !awayPartnership || !match || !selectedSetId) {
      console.error('Cannot complete set, match, set, homePartnership or awayPartnership is not defined, this is a bug');
      return;
    }

    if(matchStattingSettings.keepScore && homePartnership.score === awayPartnership.score) {
      enqueueSnackbar('Cannot complete set, scores are tied', {variant: 'error', autoHideDuration: 5000});
      return;
    }
    const winningPartnershipId = homePartnership.score > awayPartnership.score ? homePartnership.partnershipId : awayPartnership.partnershipId;

    const completeSetDto: CompleteSetDto = {
      setId: selectedSetId,
      homeScore: homePartnership.score,
      awayScore: awayPartnership.score,
      winningPartnershipId
    }
    const updatedMatch = await completeSet(match.matchId, completeSetDto);
    setMatch(updatedMatch);
  }

  const handleStartNewSetClick = async () => {
    const updatedMatch = await startNewSet(match!.matchId);
    setMatch(updatedMatch);
    setSelectedSetId(updatedMatch.sets[updatedMatch.sets.length - 1].setId);
  }

  const canCompleteSet = () => {
    const requiredPropertiesDefined = !!(match && homePartnership && awayPartnership && selectedSetId);
    const scoreIsValid = homePartnership?.score !== awayPartnership?.score;
    return requiredPropertiesDefined && (scoreIsValid || !matchStattingSettings.keepScore);
  }

  const handleSelectedSetChange = (event: SelectChangeEvent<string>) => {
    const setIndexString = event.target.value.split(' ')[0];
    const setIndex = parseInt(setIndexString.slice(1, setIndexString.length)) - 1;
    const set = match?.sets[setIndex]!;
    setSelectedSetId(set.setId);
  }
  //endregion

  //region Additional controls
  const swapCourtSides = () => {
    const initialBottomPartnershipId = homePartnership!.partnershipId === initialTopPartnershipId ? awayPartnership!.partnershipId : homePartnership!.partnershipId;
    setInitialTopPartnershipId(initialBottomPartnershipId)
  }

  const handleSettingsClick = () => {
    setMatchStattingSettingsModalOpen(true);
  }

  const handleSettingsClose = () => {
    setMatchStattingSettingsModalOpen(false);
  }

  const handleSettingSave = (settings: MatchStattingSettings) => {
    setMatchStattingSettings(settings);
    handleSettingsClose();
  }
  //endregion

  //region Misc display helpers
  const isTransitionState = (currentPointState: CurrentPointState) => {
    return currentPointState === CurrentPointState.DigTransition || currentPointState === CurrentPointState.BlockTransition || currentPointState === CurrentPointState.FreeBallTransition;
  }
  //endregion

  const handleDeletePointClick = (pointId: string) => {
    if(window.confirm('Delete point?')) {
      resetEphemeralPointState();
      setSelectedPointId(null);
      deletePoint(pointId)
        .then(() => fetchAndUpdateSetPointHistory(match!.season, selectedSetId!))
    }
  }

  const handleJumpToCurrentClick = (pointId: string) => {
    resetEphemeralPointState();
    setSelectedPointId(null);
  }

  const handleHistoricalPointClick = (point: StattedPointDto) => {
    resetEphemeralPointState();
    if(point.pointId === selectedPointId) {
      setSelectedPointId(null);
    } else {
      setSelectedPointId(point.pointId);
    }
  }

  const courtProps: CourtProps = {
    isRotated: courtIsRotated,
    sectionAdditionalClasses: {
      receivingOutOfBounds: receivingOutOfBoundsAdditionalClasses(),
      receivingInBounds: receivingInBoundsAdditionalClasses(),
      attackLocationOrFree: attackLocationOrFreeAdditionalClasses(),
      shotLocation: shotLocationAdditionalClasses(),
      attackingOutOfBounds: attackingOutOfBoundsAdditionalClasses()
    },
    locationDisplaySettings: {
      showServeReceiveLocation: showServeReceiveLocationsOverride !== undefined ? showServeReceiveLocationsOverride : showServeReceiveLocation(),
      showAttackLocations: showAttackLocationsOverride !== undefined ? showAttackLocationsOverride : showAttackLocations(),
    },
    locationConditionalStyles: {
      shotLocationSection: shotLocationSectionStyle,
      attackOnLocationSection: attackOnLocationSectionStyle,
      attackOffLocationSection: attackOffLocationSectionStyle,
      freeBallLocationSection: freeBallLocationSectionStyle,
      serveReceiveSection: serveReceiveSectionStyle,
      attackOutOfBoundsLocation: attackOutOfBoundsLocationStyle,
      serveOutOfBoundLocation: serveOutOfBoundsLocationStyle
    },
    clickHandlers: {
      handleServeReceiveSectionClick,
      handleAttackOnLocationClick,
      handleAttackOffLocationClick,
      handleShotLocationSectionClick,
      handleAttackOutClick: handleAttackOutError,
      handleOutOfBoundsServeClick: handleOutOfBoundsServe,
      handleFreeBallClick
    }
  }

  const matchStattingLeftSidebarProps: MatchStattingLeftSidebarProps = {
    data: {
      match: match!,
      players: [topPartnership?.playerOne!, topPartnership?.playerTwo!, bottomPartnership?.playerOne!, bottomPartnership?.playerTwo!],
      fullPointHistory,
      homePartnership: homePartnership!,
      selectedSet: selectedSet!,
      selectedPointId: selectedPointId!
    },
    handlers: {
      handleSelectedSetChange,
      handleServerPlayerIdChange: setServingPlayerId,
      handleUndoClick,
      handleCompleteSetClick,
      handleStartNewSetClick,
      handleSwapCourtSidesClick: swapCourtSides,
      handleHistoricalPointClick,
      handleSettingsClick
    },
    canCompleteSet,
    canUndo
  }

  const topPartnershipContainerProps: MatchPartnershipContainerProps = {
    handlers: {
      handleUpdatePartnership: (partnership: PartnershipWithState) => updateTopOrBottomPartnershipState(true, partnership),
      handleSetServer: setServingPlayerId
    },
    data: {
      isTopPartnership: true,
      partnership: topPartnership!,
      opponentPartnership: bottomPartnership!,
      pointHistory,
      servingPlayerId
    }
  }

  const bottomPartnershipContainerProps: MatchPartnershipContainerProps = {
    handlers: {
      handleUpdatePartnership: (partnership: PartnershipWithState) => updateTopOrBottomPartnershipState(false, partnership),
      handleSetServer: setServingPlayerId
    },
    data: {
      isTopPartnership: false,
      partnership: bottomPartnership!,
      opponentPartnership: topPartnership!,
      pointHistory,
      servingPlayerId
    }
  }

  return (
    <div className={'page-background'}>
      <MatchStattingSettingsModal open={matchStattingSettingsModalOpen} onClose={handleSettingsClose} settings={matchStattingSettings} onSave={handleSettingSave}/>
      <Box className={`page-content-container-stat-court`}>
        {isLoading ? <LinearProgress/> : topPartnership && bottomPartnership && allPlayerStats &&
        <>
          <Box className={`player-and-court-container-stat-court`}>
            <MatchPartnershipContainer handlers={topPartnershipContainerProps.handlers} data={topPartnershipContainerProps.data}/>
            {/*<Box className={'player-container-stat-court'}>*/}
            {/*  <PlayerCard playerInfo={getPartnershipRightPlayer(topPartnership)} playerStats={playerStats(getPartnershipRightPlayer(topPartnership))} isServing={playerIsServing(getPartnershipRightPlayer(topPartnership))}/>*/}
            {/*  <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>*/}
            {/*    <IconButton style={{marginBottom: '8px', cursor: 'pointer', transform: 'scale(1.5)'}} onClick={handleTopPartnershipSideSwitchClick}><SwapHoriz/></IconButton>*/}
            {/*    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '60px'}}>*/}
            {/*      <Typography variant={"h3"}>{topPartnership.score}</Typography>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <PlayerCard playerInfo={getPartnershipLeftPlayer(topPartnership)} playerStats={playerStats(getPartnershipLeftPlayer(topPartnership))} isServing={playerIsServing(getPartnershipLeftPlayer(topPartnership))}/>*/}
            {/*</Box>*/}
            <Box className={'court-and-button-gutters-container'}>
              <MatchStattingLeftSidebar
                data={matchStattingLeftSidebarProps.data}
                handlers={matchStattingLeftSidebarProps.handlers}
                canUndo={matchStattingLeftSidebarProps.canUndo}
                canCompleteSet={matchStattingLeftSidebarProps.canCompleteSet}
              />
              <div style={{width: '350px'}}>
                <Court
                  isRotated={courtProps.isRotated}
                  sectionAdditionalClasses={courtProps.sectionAdditionalClasses}
                  locationDisplaySettings={courtProps.locationDisplaySettings}
                  locationConditionalStyles={courtProps.locationConditionalStyles}
                  clickHandlers={courtProps.clickHandlers}
                />
              </div>
              {selectedPointId !== null ?
              <Box className={'conditional-buttons-gutter'}>
                <Button variant={'contained'} onClick={() => handleJumpToCurrentClick(selectedPointId)}>Jump To Current</Button>
                {/*<Button variant={'contained'} color={'error'} onClick={() => handleDeletePointClick(selectedPointId)}>Delete</Button>*/}
              </Box> :
              <Box className={'conditional-buttons-gutter'}>
                {currentPointState === CurrentPointState.PendingServe && <Button onClick={() => handleServeInNet()} variant={'contained'}>Net Serve</Button>}
                {currentPointState === CurrentPointState.PendingServe && <Button onClick={() => handleServeFault()} variant={'contained'}>Service Fault</Button>}
                {currentPointState === CurrentPointState.ServeInPlay && <Button onClick={() => handleAce()} variant={'contained'}>Ace</Button>}
                {currentPointState === CurrentPointState.PendingAttack && <Button onClick={() => handleToolKill()} variant={'contained'} style={{fontSize: '14px'}}>Tool Kill</Button>}
                {currentPointState === CurrentPointState.PendingAttack && <Button onClick={() => handleBlockKill(AttackType.Swing)} variant={'contained'} style={{fontSize: '14px'}}>Block Kill (Swing)</Button>}
                {currentPointState === CurrentPointState.PendingAttack && <Button onClick={() => handleBlockKill(AttackType.Shot)} variant={'contained'} style={{fontSize: '14px'}}>Block Kill (Shot)</Button>}
                {currentPointState === CurrentPointState.PendingAttack && <Button onClick={() => handleBlockRecycle(AttackType.Swing)} variant={'contained'} style={{fontSize: '14px'}}>Block Covered (Swing)</Button>}
                {currentPointState === CurrentPointState.PendingAttack && <Button onClick={() => handleBlockRecycle(AttackType.Shot)} variant={'contained'} style={{fontSize: '14px'}}>Block Covered (Shot)</Button>}
                {currentPointState === CurrentPointState.PendingAttack && <Button onClick={() => handleFaultPoint(servingPartnership!.partnershipId)} variant={'contained'} style={{fontSize: '14px'}}>Fault (Serving Team Point)</Button>}
                {currentPointState === CurrentPointState.PendingAttack && <Button onClick={() => handleFaultPoint(receivingPartnership!.partnershipId)} variant={'contained'} style={{fontSize: '14px'}}>Fault (Receiving Team Point)</Button>}
                {currentPointState === CurrentPointState.PendingAttack && <Button onClick={() => handleAttackInNet(AttackType.Swing)} variant={'contained'} style={{fontSize: '14px'}}>Net (Swing)</Button>}
                {currentPointState === CurrentPointState.PendingAttack && <Button onClick={() => handleAttackInNet(AttackType.Shot)} variant={'contained'} style={{fontSize: '14px'}}>Net (Shot)</Button>}
                {currentPointState === CurrentPointState.AttackInPlay && <Button onClick={() => handleAttackKill(AttackType.Swing)} variant={'contained'} style={{fontSize: '14px'}}>Kill (Swing)</Button>}
                {currentPointState === CurrentPointState.AttackInPlay && <Button onClick={() => handleAttackKill(AttackType.Shot)} variant={'contained'} style={{fontSize: '14px'}}>Kill (Shot)</Button>}
                {currentPointState === CurrentPointState.AttackInPlay && <Button onClick={() => handleAttackDig(AttackType.Swing)} variant={'contained'} style={{fontSize: '14px'}}>Dig (Swing)</Button>}
                {currentPointState === CurrentPointState.AttackInPlay && <Button onClick={() => handleAttackDig(AttackType.Shot)} variant={'contained'} style={{fontSize: '14px'}}>Dig (Shot)</Button>}
                {currentPointState === CurrentPointState.AttackOutOfPlay && <Button onClick={() => handleAttackOutFinished(AttackType.Swing)} variant={'contained'} style={{fontSize: '14px'}}>Out (Swing)</Button>}
                {currentPointState === CurrentPointState.AttackOutOfPlay && <Button onClick={() => handleAttackOutFinished(AttackType.Shot)} variant={'contained'} style={{fontSize: '14px'}}>Out (Shot)</Button>}
                {isTransitionState(currentPointState) && <Button onClick={() => handleTransitionPoint(servingPartnership!.partnershipId)} variant={'contained'} style={{fontSize: '14px'}}>Serving Team Point</Button>}
                {isTransitionState(currentPointState) && <Button onClick={() => handleTransitionPoint(receivingPartnership!.partnershipId)} variant={'contained'} style={{fontSize: '14px'}}>Receiving Team Point</Button>}
              </Box>}
            </Box>
            <MatchPartnershipContainer handlers={bottomPartnershipContainerProps.handlers} data={bottomPartnershipContainerProps.data}/>
            {/*<Box className={'player-container-stat-court'}>*/}
            {/*  <PlayerCard playerInfo={getPartnershipLeftPlayer(bottomPartnership)} playerStats={playerStats(getPartnershipLeftPlayer(bottomPartnership))} isServing={playerIsServing(getPartnershipLeftPlayer(bottomPartnership))}/>*/}
            {/*  <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>*/}
            {/*    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '60px'}}>*/}
            {/*      <Typography variant={"h3"}>{bottomPartnership.score}</Typography>*/}
            {/*    </div>*/}
            {/*    <IconButton style={{marginBottom: '8px', cursor: 'pointer', transform: 'scale(1.5)'}} onClick={handleBottomPartnershipSideSwitchClick}><SwapHoriz/></IconButton>*/}
            {/*  </div>*/}
            {/*  <PlayerCard*/}
            {/*    playerInfo={getPartnershipRightPlayer(bottomPartnership)}*/}
            {/*    playerStats={playerStats(getPartnershipRightPlayer(bottomPartnership))}*/}
            {/*    isServing={playerIsServing(getPartnershipRightPlayer(bottomPartnership))}/>*/}
            {/*</Box>*/}
          </Box>
        </>}
      </Box>
    </div>
  );
};

export default MatchStattingPage;
