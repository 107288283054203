import React, {useEffect} from 'react';
import './App.css';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import PlayerManagementList from "./pages/player-management/PlayerManagementList";
import Layout from "./components/Layout";
import {SnackbarProvider} from "notistack";
import MatchesListPage from "./pages/matches/MatchesListPage";
import AnalysisPage from "./pages/analysis/AnalysisPage";
import LoginPage from "./pages/login/LoginPage";
import LoginCallbackPage from "./pages/login/LoginCallbackPage";
import AuthGuard from "./components/AuthGuard";
import SettingsPage from "./pages/settings/SettingsPage";
import MatchStattingPage from "./pages/matches/MatchStattingPage";

function App() {
  useEffect(() => {
    document.title = 'Sandy';
  }, []);

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#673ab7',
      },
      secondary: {
        main: '#ff9100',
      },
      background: {
        default: '#121212',
        paper: '#1e1e1e',
      },
      text: {
        primary: '#ffffff',
        secondary: '#eeeeee',
      },
    },
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif',
      fontSize: 16,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider/>
      <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route element={<AuthGuard><Layout/></AuthGuard>}>
                <Route path="" element={<AuthGuard><MatchesListPage /></AuthGuard>}/>
                <Route path="/player-management" element={<AuthGuard><PlayerManagementList/></AuthGuard>} />
                <Route path="/matches" element={<AuthGuard><MatchesListPage/></AuthGuard>} />
                <Route path="match-statting">
                  <Route path=":matchId" element={<AuthGuard><MatchStattingPage/></AuthGuard>}/>
                </Route>
                <Route path="/analysis" element={<AuthGuard><AnalysisPage /></AuthGuard>} />
                <Route path="/settings" element={<AuthGuard><SettingsPage /></AuthGuard>} />
              </Route>
              <Route path="login" element={<LoginPage/>} />
              <Route path="login-callback" element={<LoginCallbackPage/>} />
            </Route>
          </Routes>
        </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
