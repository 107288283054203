import {AceServe, isAceServe, isErrorServe, ReceivedServe, ServeInPlayLocation, ServiceOutcome} from "../types/servce-info.type";
import {StattedPointDto} from "../dtos/statted-point.dto";
import {
  DetailedIndividualStats, DetailedPartnershipStats,
  DetailedStatTotals,
  emptyIndividualPointsBreakdown, emptyPartnershipPointsBreakdown,
  IndividualPointsBreakdown,
  PartnershipPointsBreakdown,
  PointsBreakdown
} from "../types/analysis-info.type";
import {
  AttackLocationInfo,
  AttackOutcome, AttackOutLocation, AttackWithShotLocation,
  isAttackWithoutAttackType,
  isAttackWithoutAttackTypeOrLocation,
  isAttackWithOutLocation,
  isAttackWithoutShotLocation,
  isAttackWithShotLocation, ShotLocation
} from "../types/attack-info.type";
import {PlayerStatsDto} from "../dtos/player.dto";
import {PartnershipDto} from "../dtos/partnership.dto";
import {buildPointHandler} from "../utils/statted-point-helpers";

export const getPointsBreakdown = (points: StattedPointDto[], playerIds: string[], playerPartnershipIds: string[]): PointsBreakdown => {
  const uniquePlayerIds = Array.from(new Set(playerIds));
  const uniquePartnershipIds = Array.from(new Set(playerPartnershipIds));

  const partnershipBreakdown = uniquePartnershipIds.reduce((acc, partnershipId) => {
    const breakdown = getPartnershipPointsBreakdown(points, partnershipId);
    return {
      servesWon: [...acc.servesWon, ...breakdown.servesWon],
      servesLost: [...acc.servesLost, ...breakdown.servesLost],
      receivesWon: [...acc.receivesWon, ...breakdown.receivesWon],
      receivesLost: [...acc.receivesLost, ...breakdown.receivesLost],
      serveTransitionPointsWon: [...acc.serveTransitionPointsWon, ...breakdown.serveTransitionPointsWon],
      serveTransitionPointsLost: [...acc.serveTransitionPointsLost, ...breakdown.serveTransitionPointsLost],
      receiveTransitionPointsWon: [...acc.receiveTransitionPointsWon, ...breakdown.receiveTransitionPointsWon],
      receiveTransitionPointsLost: [...acc.receiveTransitionPointsLost, ...breakdown.receiveTransitionPointsLost]
    }
  }, emptyPartnershipPointsBreakdown);

  const individualBreakdown = uniquePlayerIds.reduce((acc, playerId) => {
    const breakdown = getIndividualPointsBreakdown(points, playerId, playerPartnershipIds);
    return {
      sideoutOpportunities: [...acc.sideoutOpportunities, ...breakdown.sideoutOpportunities],
      successfulSideoutPoints: [...acc.successfulSideoutPoints, ...breakdown.successfulSideoutPoints],
      inKills: [...acc.inKills, ...breakdown.inKills],
      attacksDug: [...acc.attacksDug, ...breakdown.attacksDug],
      attacksOut: [...acc.attacksOut, ...breakdown.attacksOut],
      aces: [...acc.aces, ...breakdown.aces],
      serviceErrors: [...acc.serviceErrors, ...breakdown.serviceErrors],
      aced: [...acc.aced, ...breakdown.aced],
      toolKills: [...acc.toolKills, ...breakdown.toolKills],
      blocked: [...acc.blocked, ...breakdown.blocked],
      recycles: [...acc.recycles, ...breakdown.recycles],
      netSwings: [...acc.netSwings, ...breakdown.netSwings],
      ownFaults: [...acc.ownFaults, ...breakdown.ownFaults],
      oppFaults: [...acc.oppFaults, ...breakdown.oppFaults],
      freeBalls: [...acc.freeBalls, ...breakdown.freeBalls],
      servesWon: [...acc.servesWon, ...breakdown.servesWon],
      servesLost: [...acc.servesLost, ...breakdown.servesLost],
      receivesWon: [...acc.receivesWon, ...breakdown.receivesWon],
      receivesLost: [...acc.receivesLost, ...breakdown.receivesLost],
      serveTransitionPointsWon: [...acc.serveTransitionPointsWon, ...breakdown.serveTransitionPointsWon],
      serveTransitionPointsLost: [...acc.serveTransitionPointsLost, ...breakdown.serveTransitionPointsLost],
      receiveTransitionPointsWon: [...acc.receiveTransitionPointsWon, ...breakdown.receiveTransitionPointsWon],
      receiveTransitionPointsLost: [...acc.receiveTransitionPointsLost, ...breakdown.receiveTransitionPointsLost]
    }
  }, emptyIndividualPointsBreakdown);

  return {individual: individualBreakdown, partnership: partnershipBreakdown};
}

export const getDetailedStatTotals = (pointsBreakdown: PointsBreakdown): DetailedStatTotals => {
  return {
    individual: getIndividualDetailedStatTotals(pointsBreakdown.individual),
    partnership: getPartnershipDetailedStatTotals(pointsBreakdown.partnership)
  }
}

export const calculatePlayersStatsFromPoints = (pointsHistory: StattedPointDto[], playerIds: string[], playerPartnershipId: string): PlayerStatsDto => {
  const pointsBreakdown = getPointsBreakdown(pointsHistory, playerIds, [playerPartnershipId]);
  const statTotals = getDetailedStatTotals(pointsBreakdown);
  const totalKills = statTotals.individual.totalKills;
  const totalErrorsAndAced = statTotals.individual.totalErrorsAndAced;

  const opportunities = statTotals.individual.sideoutOpportunities;
  return {
    sideout: {
      errors: totalErrorsAndAced,
      kills: totalKills,
      pointsWon: -1,
      opportunities,
      percentage: (totalKills - totalErrorsAndAced) / opportunities
    },
    firstBallSideout: {
      errors: totalErrorsAndAced,
      kills: totalKills,
      pointsWon: -1,
      opportunities,
      percentage: (totalKills - totalErrorsAndAced) / opportunities
    },
    serving: {
      errors: statTotals.individual.serviceErrors,
      aces: statTotals.individual.aces,
      pointsWon: -1,
      opportunities: -1,
      acePercentage: -1,
      serviceErrorPercentage: -1
    },
    matchesPlayed: 1,
    setsPlayed: 1
  }
}

export const getPointsByServeLocation = (points: StattedPointDto[], playerIds: string[]): {location: ServeInPlayLocation, points: StattedPointDto[]}[] => {
  const receivingPoints = points.filter(p => !isErrorServe(p.serveInfo) && playerIds.includes(p.serveInfo.receivingPlayerId));
  const locations = receivingPoints.reduce((acc, p) => {
    const pointLocation = (p.serveInfo as (ReceivedServe | AceServe)).inPlayLocation;
    const previousInfo = acc.find(i => i.location.location === pointLocation.location && i.location.side === pointLocation.side) ?? {location: pointLocation, points: []};
    const newInfo = {...previousInfo, points: [...previousInfo.points, p]};
    return [...acc.filter(l => l.location.location !== pointLocation.location || l.location.side !== pointLocation.side), newInfo];
  }, [] as {location: ServeInPlayLocation, points: StattedPointDto[]}[]);
  return locations;
}

export const getPointsByAttackLocation = (points: StattedPointDto[], playerIds: string[]): {location: AttackLocationInfo, points: StattedPointDto[]}[] => {
  const attackingPoints = points.filter(p => playerIds.includes(p.attackInfo?.attackingPlayerId ?? 'IGNORE'));
  const freeBallPoints = attackingPoints.filter(p => p.attackInfo?.outcome === AttackOutcome.FreeBall); // TODO: Add free ball support here
  const nonFreeBallPoints = attackingPoints.filter(p => p.attackInfo?.outcome !== AttackOutcome.FreeBall);
  const locations = nonFreeBallPoints.reduce((acc, p) => {
    if(!p.attackInfo || isAttackWithoutAttackTypeOrLocation(p.attackInfo)) return acc;
    const pointLocation = (p.attackInfo as AttackLocationInfo);
    const previousInfo = acc.find(i => i.location.attackLocation === pointLocation.attackLocation && i.location.attackSubLocation === pointLocation.attackSubLocation) ?? {location: pointLocation, points: []};
    const newInfo = {...previousInfo, points: [...previousInfo.points, p]};
    return [...acc.filter(l => l.location.attackLocation !== pointLocation.attackLocation || l.location.attackSubLocation !== pointLocation.attackSubLocation), newInfo];
  }, [] as {location: AttackLocationInfo, points: StattedPointDto[]}[]);
  return locations;
}

export const getAttacksByShotLocation = (points: StattedPointDto[], playerIds: string[]): {location: ShotLocation, points: StattedPointDto[]}[]=> {
  const pointsWithShotLocation = points.filter(p => p.attackInfo && isAttackWithShotLocation(p.attackInfo) && playerIds.includes(p.attackInfo.attackingPlayerId));
  const locations = pointsWithShotLocation.reduce((acc, p) => {
    const pointLocation = (p.attackInfo as AttackWithShotLocation).shotLocation;
    const previousInfo = acc.find(i => i.location === pointLocation) ?? {location: pointLocation, points: []};
    const newInfo = {...previousInfo, points: [...previousInfo.points, p]};
    return [...acc.filter(l => l.location !== pointLocation), newInfo];
  }, [] as {location: ShotLocation, points: StattedPointDto[]}[]);
  return locations;
}

export const getAttacksToShotLocation = (points: StattedPointDto[], shotLocation: ShotLocation, playerIds: string[]): StattedPointDto[] => {
  return points.filter(p => {
    if(!p.attackInfo || !playerIds.includes(p.attackInfo.attackingPlayerId)) return false;
    return isAttackWithShotLocation(p.attackInfo) ? p.attackInfo!.shotLocation === shotLocation : false;
  })
}

export const playerParticipatedInPoint = (playerId: string, point: StattedPointDto) => {
  return point.serverPlayerId === playerId ||
    (point.serveInfo as (AceServe | ReceivedServe))?.receivingPlayerId === playerId ||
    point.attackInfo?.attackingPlayerId === playerId;
}

export const partnershipParticipatedInPoint = (partnershipId: string, point: StattedPointDto) => {
  return point.receivingPartnershipId === partnershipId || point.servingPartnershipId === partnershipId;
}

export const playerInPartnership = (playerId: string, partnership: PartnershipDto) =>
  partnership.playerOne.playerId === playerId || partnership.playerTwo.playerId === playerId

export const isTransitionPoint = buildPointHandler(
  errorServe => false,
  aceServe => false,
  (_, attackWithoutAttackTypeOrLocation) => true, // Free ball
  (_, attackWithoutShotLocation) => attackWithoutShotLocation.outcome === AttackOutcome.BlockRecycle,
  (_, attackWithShotLocation) => attackWithShotLocation.outcome === AttackOutcome.Dug,
  (_, attackWithOutLocation) => false,
  (_, attackWithoutAttackType) => false
)

const getIndividualPointsBreakdown = (points: StattedPointDto[], playerId: string, playerPartnershipIds: string[]): IndividualPointsBreakdown => points.reduce((acc, p) => {
  if(p.serverPlayerId === playerId) {
    if (isErrorServe(p.serveInfo)) {
      return {...acc, serviceErrors: [...acc.serviceErrors, p], servesLost: [...acc.servesLost, p]};
    } else if (isAceServe(p.serveInfo)) {
        return {...acc, aces: [...acc.aces, p], servesWon: [...acc.servesWon, p]};
    } else {
      if(playerPartnershipIds.includes(p.winningPartnershipId)) {
        const serveTransitionPointsWon = isTransitionPoint(p) ? [...acc.serveTransitionPointsWon, p] : acc.serveTransitionPointsWon;
        return {...acc, servesWon: [...acc.servesWon, p], serveTransitionPointsWon};
      } else {
        const serveTransitionPointsLost = isTransitionPoint(p) ? [...acc.serveTransitionPointsLost, p] : acc.serveTransitionPointsLost;
        return {...acc, servesLost: [...acc.servesLost, p], serveTransitionPointsLost};
      }
    }
  }

  if (isAceServe(p.serveInfo) && p.serveInfo.receivingPlayerId === playerId) {
    return {...acc, aced: [...acc.aced, p], sideoutOpportunities: [...acc.sideoutOpportunities, p], receivesLost: [...acc.receivesLost, p]};
  }

  if (!p.attackInfo || p.attackInfo.attackingPlayerId !== playerId) return acc;

  if (isAttackWithoutShotLocation(p.attackInfo)) {
    switch (p.attackInfo.outcome) {
      case "BlockKill":
        return {...acc, blocked: [...acc.blocked, p], sideoutOpportunities: [...acc.sideoutOpportunities, p], receivesLost: [...acc.receivesLost, p]};
      case "BlockRecycle":
        const sideoutPointsWon = playerPartnershipIds.includes(p.winningPartnershipId) ? [...acc.successfulSideoutPoints, p] : acc.successfulSideoutPoints;
        const receivesWon = playerPartnershipIds.includes(p.winningPartnershipId) ? [...acc.receivesWon, p] : acc.receivesWon;
        const receivesLost = playerPartnershipIds.includes(p.winningPartnershipId) ? acc.receivesLost : [...acc.receivesLost, p];
        const receiveTransitionPointsWon = playerPartnershipIds.includes(p.winningPartnershipId) ? [...acc.receiveTransitionPointsWon, p] : acc.receiveTransitionPointsWon;
        const receiveTransitionPointsLost = playerPartnershipIds.includes(p.winningPartnershipId) ? acc.receiveTransitionPointsLost : [...acc.receiveTransitionPointsLost, p];
        return {...acc, recycles: [...acc.recycles, p], sideoutOpportunities: [...acc.sideoutOpportunities, p], successfulSideoutPoints: sideoutPointsWon,
                receivesWon, receivesLost, receiveTransitionPointsWon, receiveTransitionPointsLost};
      case "ToolKill":
        return {...acc, toolKills: [...acc.toolKills, p], sideoutOpportunities: [...acc.sideoutOpportunities, p], successfulSideoutPoints: [...acc.successfulSideoutPoints, p], receivesWon: [...acc.receivesWon, p]};
      case "BallInNet":
        return {...acc, netSwings: [...acc.netSwings, p], sideoutOpportunities: [...acc.sideoutOpportunities, p], receivesLost: [...acc.receivesLost, p]};
    }
  } else if (isAttackWithShotLocation(p.attackInfo)) {
    switch (p.attackInfo.outcome) {
      case "InKill":
        return {...acc, inKills: [...acc.inKills, p], sideoutOpportunities: [...acc.sideoutOpportunities, p], successfulSideoutPoints: [...acc.successfulSideoutPoints, p], receivesWon: [...acc.receivesWon, p]};
      case "Dug":
        const sideoutPointsWon = playerPartnershipIds.includes(p.winningPartnershipId) ? [...acc.successfulSideoutPoints, p] : acc.successfulSideoutPoints;
        const receivesWon = playerPartnershipIds.includes(p.winningPartnershipId) ? [...acc.receivesWon, p] : acc.receivesWon;
        const receivesLost = playerPartnershipIds.includes(p.winningPartnershipId) ? acc.receivesLost : [...acc.receivesLost, p];
        const receiveTransitionPointsWon = playerPartnershipIds.includes(p.winningPartnershipId) ? [...acc.receiveTransitionPointsWon, p] : acc.receiveTransitionPointsWon;
        const receiveTransitionPointsLost = playerPartnershipIds.includes(p.winningPartnershipId) ? acc.receiveTransitionPointsLost : [...acc.receiveTransitionPointsLost, p];
        return {...acc, attacksDug: [...acc.attacksDug, p], sideoutOpportunities: [...acc.sideoutOpportunities, p], successfulSideoutPoints: sideoutPointsWon,
                receivesWon, receivesLost, receiveTransitionPointsWon, receiveTransitionPointsLost};
    }
  } else if (isAttackWithOutLocation(p.attackInfo)) {
    if (p.attackInfo.outLocation === AttackOutLocation.Net) {
      return {...acc, netSwings: [...acc.netSwings, p], sideoutOpportunities: [...acc.sideoutOpportunities, p], receivesLost: [...acc.receivesLost, p]};
    } else {
      return {...acc, attacksOut: [...acc.attacksOut, p], sideoutOpportunities: [...acc.sideoutOpportunities, p], receivesLost: [...acc.receivesLost, p]};
    }
  } else if (isAttackWithoutAttackType(p.attackInfo)) {
    if (playerPartnershipIds.includes(p.winningPartnershipId)) {
      return {...acc, oppFaults: [...acc.oppFaults, p], sideoutOpportunities: [...acc.sideoutOpportunities, p], successfulSideoutPoints: [...acc.successfulSideoutPoints, p], receivesWon: [...acc.receivesWon, p]};
    } else {
      return {...acc, ownFaults: [...acc.ownFaults, p], sideoutOpportunities: [...acc.sideoutOpportunities, p], receivesLost: [...acc.receivesLost, p]};
    }
  } else if (isAttackWithoutAttackTypeOrLocation(p.attackInfo)) {
    const sideoutPointsWon = playerPartnershipIds.includes(p.winningPartnershipId) ? [...acc.successfulSideoutPoints, p] : acc.successfulSideoutPoints;
    const receivesWon = playerPartnershipIds.includes(p.winningPartnershipId) ? [...acc.receivesWon, p] : acc.receivesWon;
    const receivesLost = playerPartnershipIds.includes(p.winningPartnershipId) ? acc.receivesLost : [...acc.receivesLost, p];
    const receiveTransitionPointsWon = playerPartnershipIds.includes(p.winningPartnershipId) ? [...acc.receiveTransitionPointsWon, p] : acc.receiveTransitionPointsWon;
    const receiveTransitionPointsLost = playerPartnershipIds.includes(p.winningPartnershipId) ? acc.receiveTransitionPointsLost : [...acc.receiveTransitionPointsLost, p];
    return {...acc, freeBalls: [...acc.freeBalls, p], sideoutOpportunities: [...acc.sideoutOpportunities, p], successfulSideoutPoints: sideoutPointsWon,
            receivesWon, receivesLost, receiveTransitionPointsWon, receiveTransitionPointsLost};
  } else {
    console.error('Unknown attack info', p.attackInfo);
    return acc;
  }
}, emptyIndividualPointsBreakdown)

const getPartnershipPointsBreakdown = (points: StattedPointDto[], partnershipId: string): PartnershipPointsBreakdown => points.reduce((acc, p) => {
  if (p.servingPartnershipId === partnershipId) {
    if(p.winningPartnershipId === partnershipId) {
      const serveTransitionPointsWon = isTransitionPoint(p) ? [...acc.serveTransitionPointsWon, p] : acc.serveTransitionPointsWon;
      return {...acc, servesWon: [...acc.servesWon, p], serveTransitionPointsWon};
    } else {
      const serveTransitionPointsLost = isTransitionPoint(p) ? [...acc.serveTransitionPointsLost, p] : acc.serveTransitionPointsLost;
      return {...acc, servesLost: [...acc.servesLost, p], serveTransitionPointsLost};
    }
  } else if (p.receivingPartnershipId === partnershipId) {
    if(p.winningPartnershipId === partnershipId) {
      const receiveTransitionPointsWon = isTransitionPoint(p) ? [...acc.receiveTransitionPointsWon, p] : acc.receiveTransitionPointsWon;
      return {...acc, receivesWon: [...acc.receivesWon, p], receiveTransitionPointsWon};
    } else {
      const receiveTransitionPointsLost = isTransitionPoint(p) ? [...acc.receiveTransitionPointsLost, p] : acc.receiveTransitionPointsLost;
      return {...acc, receivesLost: [...acc.receivesLost, p], receiveTransitionPointsLost};
    }
  } else {
    return acc;
  }
}, emptyPartnershipPointsBreakdown)


const getIndividualDetailedStatTotals = (pointsBreakdown: IndividualPointsBreakdown): DetailedIndividualStats => {
  const totalKills = pointsBreakdown.inKills.length + pointsBreakdown.toolKills.length + pointsBreakdown.oppFaults.length;
  const totalErrors = pointsBreakdown.attacksOut.length + pointsBreakdown.blocked.length + pointsBreakdown.netSwings.length + pointsBreakdown.ownFaults.length;
  const successfulSideouts = pointsBreakdown.successfulSideoutPoints.length;
  const totalErrorsAndAced = totalErrors + pointsBreakdown.aced.length;
  const opportunities = pointsBreakdown.sideoutOpportunities.length;

  const totalServes = pointsBreakdown.servesWon.length + pointsBreakdown.servesLost.length;
  const totalReceives = pointsBreakdown.receivesWon.length + pointsBreakdown.receivesLost.length;
  const totalPoints = totalServes + totalReceives;
  const servePointsWon = pointsBreakdown.servesWon.length;
  const receivePointsWon = pointsBreakdown.receivesWon.length;
  const serveTransitionPointsWon = pointsBreakdown.serveTransitionPointsWon.length;
  const serveTransitionPointsLost = pointsBreakdown.serveTransitionPointsLost.length;
  const receiveTransitionPointsWon = pointsBreakdown.receiveTransitionPointsWon.length;
  const receiveTransitionPointsLost = pointsBreakdown.receiveTransitionPointsLost.length;
  return {
    fbSideoutPercentage: opportunities === 0 ? 0 : (totalKills / opportunities),
    sideoutPercentage: opportunities === 0 ? 0 : (successfulSideouts / opportunities),
    hittingPercentage: opportunities === 0 ? 0 : ((totalKills - totalErrorsAndAced) / opportunities),
    sideoutOpportunities: opportunities,
    successfulSideouts,
    totalKills,
    totalErrors,
    totalErrorsAndAced,
    aces: pointsBreakdown.aces.length,
    serviceErrors: pointsBreakdown.serviceErrors.length,
    aced: pointsBreakdown.aced.length,
    toolKill: pointsBreakdown.toolKills.length,
    attackOut: pointsBreakdown.attacksOut.length,
    attacksDug: pointsBreakdown.attacksDug.length,
    blocked: pointsBreakdown.blocked.length,
    recycles: pointsBreakdown.recycles.length,
    netSwings: pointsBreakdown.netSwings.length,
    ownFaults: pointsBreakdown.ownFaults.length,
    oppFaults: pointsBreakdown.oppFaults.length,
    pointWinPercentage: totalPoints === 0 ? 0 : ((servePointsWon + receivePointsWon) / totalPoints),
    servePointWinPercentage: totalServes === 0 ? 0 : (servePointsWon / totalServes),
    receivePointWinPercentage: totalReceives === 0 ? 0 : (receivePointsWon / totalReceives),
    serveTransitionPointWinPercentage: (serveTransitionPointsWon + serveTransitionPointsLost) === 0 ? 0 : (serveTransitionPointsWon / (serveTransitionPointsWon + serveTransitionPointsLost)),
    receiveTransitionPointWinPercentage: (receiveTransitionPointsWon + receiveTransitionPointsLost) === 0 ? 0 : (receiveTransitionPointsWon / (receiveTransitionPointsWon + receiveTransitionPointsLost)),
    totalPoints,
    totalServes,
    servePointsWon,
    totalReceives,
    receivePointsWon,
    serveTransitionPointsWon,
    serveTransitionPointsLost,
    receiveTransitionPointsWon,
    receiveTransitionPointsLost
  };
}

const getPartnershipDetailedStatTotals = (pointsBreakdown: PartnershipPointsBreakdown): DetailedPartnershipStats => {
  const totalServes = pointsBreakdown.servesWon.length + pointsBreakdown.servesLost.length;
  const totalReceives = pointsBreakdown.receivesWon.length + pointsBreakdown.receivesLost.length;
  const totalPoints = totalServes + totalReceives;
  const servePointsWon = pointsBreakdown.servesWon.length;
  const receivePointsWon = pointsBreakdown.receivesWon.length;
  const serveTransitionPointsWon = pointsBreakdown.serveTransitionPointsWon.length;
  const serveTransitionPointsLost = pointsBreakdown.serveTransitionPointsLost.length;
  const receiveTransitionPointsWon = pointsBreakdown.receiveTransitionPointsWon.length;
  const receiveTransitionPointsLost = pointsBreakdown.receiveTransitionPointsLost.length;
  return {
    pointWinPercentage: totalPoints === 0 ? 0 : ((servePointsWon + receivePointsWon) / totalPoints),
    servePointWinPercentage: totalServes === 0 ? 0 : (servePointsWon / totalServes),
    receivePointWinPercentage: totalReceives === 0 ? 0 : (receivePointsWon / totalReceives),
    serveTransitionPointWinPercentage: (serveTransitionPointsWon + serveTransitionPointsLost) === 0 ? 0 : (serveTransitionPointsWon / (serveTransitionPointsWon + serveTransitionPointsLost)),
    receiveTransitionPointWinPercentage: (receiveTransitionPointsWon + receiveTransitionPointsLost) === 0 ? 0 : (receiveTransitionPointsWon / (receiveTransitionPointsWon + receiveTransitionPointsLost)),
    totalPoints,
    totalServes,
    servePointsWon,
    totalReceives,
    receivePointsWon,
    serveTransitionPointsWon,
    serveTransitionPointsLost,
    receiveTransitionPointsWon,
    receiveTransitionPointsLost
  };
}