import React, { useState } from 'react';
import { Modal, Box, Typography, Switch, FormControlLabel, Button } from '@mui/material';

export interface MatchStattingSettings {
  keepScore: boolean;
  autoSwapSides: boolean;
  autoSetServer: boolean;
}

interface MatchStattingSettingsModalProps {
  open: boolean;
  onClose: () => void;
  settings: MatchStattingSettings
  onSave: (settings: MatchStattingSettings) => void;
}

const MatchStattingSettingsModal: React.FC<MatchStattingSettingsModalProps> = ({ open, onClose, settings, onSave }) => {
  const [keepScore, setKeepScore] = useState(settings.keepScore);
  const [autoSwapSides, setAutoSwapSides] = useState(settings.autoSwapSides);
  const [autoSetServer, setAutoSetServer] = useState(settings.autoSetServer);

  const handleSave = () => {
    onSave({ keepScore, autoSwapSides, autoSetServer });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ width: 400, backgroundColor: 'background.paper', p: 4, mx: 'auto', mt: '10%', borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom>
          Match Statting Settings
        </Typography>
        <FormControlLabel
          control={<Switch checked={keepScore} onChange={(e) => setKeepScore(e.target.checked)} />}
          label="Keep Score"
        />
        <FormControlLabel
          control={<Switch checked={autoSwapSides} onChange={(e) => setAutoSwapSides(e.target.checked)} />}
          label="Auto-Swap Sides"
        />
        <FormControlLabel
          control={<Switch checked={autoSetServer} onChange={(e) => setAutoSetServer(e.target.checked)} />}
          label="Auto-Set Server"
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button onClick={onClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MatchStattingSettingsModal;