import {StattedPoint} from "sandy-shared/dist/types/statted-point.type";
import {ServeInPlayLocation, ServeOutLocation, ServiceOutcome} from "sandy-shared/dist/types/servce-info.type";
import {AttackLocation, AttackOutcome, AttackOutLocation, AttackSubLocation, AttackType, ShotLocation} from "sandy-shared/dist/types/attack-info.type";
import {PartnershipWithState} from "../pages/matches/MatchStattingPage-types";

export const serveInNetPoint = (
  pointIndex: number,
  servingPartnership: PartnershipWithState,
  receivingPartnership: PartnershipWithState,
  servingPlayerId: string
) => {
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Error,
      outLocation: ServeOutLocation.Net
    },
    winningPartnershipId: receivingPartnership.partnershipId
  };
  return point;
}

export const serveFaultPoint = (
  pointIndex: number,
  servingPartnership: PartnershipWithState,
  receivingPartnership: PartnershipWithState,
  servingPlayerId: string
) => {
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Error,
      outLocation: ServeOutLocation.Fault
    },
    winningPartnershipId: receivingPartnership.partnershipId
  };
  return point;
}

export const serveOutPoint = (
  pointIndex: number,
  servingPartnership: PartnershipWithState,
  receivingPartnership: PartnershipWithState,
  servingPlayerId: string,
  serveOutLocation: ServeOutLocation
) => {
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Error,
      outLocation: serveOutLocation
    },
    winningPartnershipId: receivingPartnership.partnershipId
  };
  return point;
}

export const acePoint = (
  pointIndex: number,
  servingPartnership: PartnershipWithState,
  receivingPartnership: PartnershipWithState,
  servingPlayerId: string,
  serveInPlayLocation: ServeInPlayLocation
) => {
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Ace,
      inPlayLocation: serveInPlayLocation,
      receivingPlayerId: receivingPartnership.playerOne.side === serveInPlayLocation.side ? receivingPartnership.playerOne.playerId : receivingPartnership.playerTwo.playerId
    },
    winningPartnershipId: servingPartnership.partnershipId
  };
  return point;
}

export const blockKillPoint = (
  pointIndex: number,
  servingPartnership: PartnershipWithState,
  receivingPartnership: PartnershipWithState,
  servingPlayerId: string,
  serveInPlayLocation: ServeInPlayLocation,
  attackType: AttackType,
  attackLocation: AttackLocation,
  attackSubLocation: AttackSubLocation
) => {
  const receivingPlayerId = getReceivingPlayerId(receivingPartnership, serveInPlayLocation);
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Received,
      inPlayLocation: serveInPlayLocation,
      receivingPlayerId
    },
    attackInfo: {
      attackingPlayerId: receivingPlayerId,
      isOnTwo: false,
      attackLocation,
      attackSubLocation,
      attackType,
      outcome: AttackOutcome.BlockKill,
    },
    winningPartnershipId: servingPartnership.partnershipId
  };
  return point;
}

export const toolKillPoint = (
    pointIndex: number,
    servingPartnership: PartnershipWithState,
    receivingPartnership: PartnershipWithState,
    servingPlayerId: string,
    serveInPlayLocation: ServeInPlayLocation,
    attackLocation: AttackLocation,
    attackSubLocation: AttackSubLocation
) => {
  const receivingPlayerId = getReceivingPlayerId(receivingPartnership, serveInPlayLocation);
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Received,
      inPlayLocation: serveInPlayLocation,
      receivingPlayerId
    },
    attackInfo: {
      attackingPlayerId: receivingPlayerId,
      isOnTwo: false,
      attackLocation,
      attackSubLocation,
      attackType: AttackType.Swing,
      outcome: AttackOutcome.ToolKill,
    },
    winningPartnershipId: receivingPartnership.partnershipId
  };
  return point;
}

export const attackKillPoint = (
  pointIndex: number,
  servingPartnership: PartnershipWithState,
  receivingPartnership: PartnershipWithState,
  servingPlayerId: string,
  serveInPlayLocation: ServeInPlayLocation,
  attackType: AttackType,
  attackLocation: AttackLocation,
  attackSubLocation: AttackSubLocation,
  shotLocation: ShotLocation
) => {
  const receivingPlayerId = getReceivingPlayerId(receivingPartnership, serveInPlayLocation);
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Received,
      inPlayLocation: serveInPlayLocation,
      receivingPlayerId
    },
    attackInfo: {
      attackingPlayerId: receivingPlayerId,
      isOnTwo: false,
      attackLocation,
      attackSubLocation,
      attackType,
      outcome: AttackOutcome.InKill,
      shotLocation: shotLocation
    },
    winningPartnershipId: receivingPartnership.partnershipId
  };
  return point;
}

export const attackInNetPoint = (
  pointIndex: number,
  servingPartnership: PartnershipWithState,
  receivingPartnership: PartnershipWithState,
  servingPlayerId: string,
  serveInPlayLocation: ServeInPlayLocation,
  attackType: AttackType,
  attackLocation: AttackLocation,
  attackSubLocation: AttackSubLocation
) => {
  const receivingPlayerId = getReceivingPlayerId(receivingPartnership, serveInPlayLocation);
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Received,
      inPlayLocation: serveInPlayLocation,
      receivingPlayerId
    },
    attackInfo: {
      attackingPlayerId: receivingPlayerId,
      isOnTwo: false,
      attackLocation,
      attackSubLocation,
      attackType,
      outcome: AttackOutcome.Out,
      outLocation: AttackOutLocation.Net
    },
    winningPartnershipId: servingPartnership.partnershipId
  };
  return point;
}

export const attackOutPoint = (
  pointIndex: number,
  servingPartnership: PartnershipWithState,
  receivingPartnership: PartnershipWithState,
  servingPlayerId: string,
  serveInPlayLocation: ServeInPlayLocation,
  attackType: AttackType,
  attackLocation: AttackLocation,
  attackSubLocation: AttackSubLocation,
  attackOutLocation: AttackOutLocation
) => {
  const receivingPlayerId = getReceivingPlayerId(receivingPartnership, serveInPlayLocation);
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Received,
      inPlayLocation: serveInPlayLocation,
      receivingPlayerId
    },
    attackInfo: {
      attackingPlayerId: receivingPlayerId,
      isOnTwo: false,
      attackLocation,
      attackSubLocation,
      attackType,
      outcome: AttackOutcome.Out,
      outLocation: attackOutLocation
    },
    winningPartnershipId: servingPartnership.partnershipId
  };
  return point;
}

export const faultPoint = (
  pointIndex: number,
  servingPartnership: PartnershipWithState,
  receivingPartnership: PartnershipWithState,
  servingPlayerId: string,
  serveInPlayLocation: ServeInPlayLocation,
  attackLocation: AttackLocation,
  attackSubLocation: AttackSubLocation,
  winningPartnershipId: string
) => {
  const receivingPlayerId = getReceivingPlayerId(receivingPartnership, serveInPlayLocation);
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Received,
      inPlayLocation: serveInPlayLocation,
      receivingPlayerId
    },
    attackInfo: {
      attackingPlayerId: receivingPlayerId,
      isOnTwo: false,
      attackLocation,
      attackSubLocation,
      outcome: AttackOutcome.Fault,
    },
    winningPartnershipId
  };
  return point;
}

export const blockCoveredTransitionPoint = (
  pointIndex: number,
  servingPartnership: PartnershipWithState,
  receivingPartnership: PartnershipWithState,
  servingPlayerId: string,
  serveInPlayLocation: ServeInPlayLocation,
  attackType: AttackType,
  attackLocation: AttackLocation,
  attackSubLocation: AttackSubLocation,
  winningPartnershipId: string
) => {
  const receivingPlayerId = getReceivingPlayerId(receivingPartnership, serveInPlayLocation);
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Received,
      inPlayLocation: serveInPlayLocation,
      receivingPlayerId
    },
    attackInfo: {
      attackingPlayerId: receivingPlayerId,
      isOnTwo: false,
      attackLocation,
      attackSubLocation,
      attackType,
      outcome: AttackOutcome.BlockRecycle,
    },
    winningPartnershipId
  };
  return point;
}

export const dugTransitionPoint = (
  pointIndex: number,
  servingPartnership: PartnershipWithState,
  receivingPartnership: PartnershipWithState,
  servingPlayerId: string,
  serveInPlayLocation: ServeInPlayLocation,
  attackType: AttackType,
  attackLocation: AttackLocation,
  attackSubLocation: AttackSubLocation,
  shotLocation: ShotLocation,
  winningPartnershipId: string
) => {
  const receivingPlayerId = getReceivingPlayerId(receivingPartnership, serveInPlayLocation);
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Received,
      inPlayLocation: serveInPlayLocation,
      receivingPlayerId
    },
    attackInfo: {
      attackingPlayerId: receivingPlayerId,
      isOnTwo: false,
      attackLocation,
      attackSubLocation,
      attackType: attackType,
      outcome: AttackOutcome.Dug,
      shotLocation: shotLocation
    },
    winningPartnershipId
  };
  return point;
}

export const freeBallTransitionPoint = (
  pointIndex: number,
  servingPartnership: PartnershipWithState,
  receivingPartnership: PartnershipWithState,
  servingPlayerId: string,
  serveInPlayLocation: ServeInPlayLocation,
  winningPartnershipId: string
) => {
  const receivingPlayerId = getReceivingPlayerId(receivingPartnership, serveInPlayLocation);
  const point: StattedPoint = {
    pointIndex,
    servingPartnershipId: servingPartnership.partnershipId,
    receivingPartnershipId: receivingPartnership.partnershipId,
    serverPlayerId: servingPlayerId,
    serveInfo: {
      outcome: ServiceOutcome.Received,
      inPlayLocation: serveInPlayLocation,
      receivingPlayerId
    },
    attackInfo: {
      attackingPlayerId: receivingPlayerId,
      isOnTwo: false,
      outcome: AttackOutcome.FreeBall
    },
    winningPartnershipId
  };
  return point;
}

const getReceivingPlayerId = (receivingPartnership: PartnershipWithState, serveInPlayLocation: ServeInPlayLocation) => {
  return receivingPartnership.playerOne.side === serveInPlayLocation.side ? receivingPartnership.playerOne.playerId : receivingPartnership.playerTwo.playerId;
}