import {
  Autocomplete,
  Box,
  Button,
  FormControl,LinearProgress,
  Modal,
  TextField,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {PlayerInfoDto} from "sandy-shared/dist/dtos/player.dto";
import { getPlayers } from "../../../services/player.service";
import {enqueueSnackbar} from "notistack";
import { getIgnoredPartnership } from "../../../services/partnership.service";

export interface ChangePlayerModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (player: PlayerInfoDto) => void;
  ignorePlayerIds: string[];
  selectedPlayerId: string;
}

export const ChangePlayerModal = ({open, onClose, onSave, ignorePlayerIds, selectedPlayerId: initialSelectedPlayerId}: ChangePlayerModalProps) => {
  const [players, setPlayers] = React.useState<PlayerInfoDto[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerInfoDto>(null!); // This will be set in useEffect

  useEffect(() => {
    if(!open) {
      return;
    }

    getPlayers().then(playersResponse => {
      const ignoredPlayerIds = [getIgnoredPartnership().playerOne.playerId, getIgnoredPartnership().playerTwo.playerId, ...ignorePlayerIds]
      const filteredAvailablePlayers = playersResponse.filter(player => !ignoredPlayerIds.includes(player.playerId));
      const selectedPlayer = filteredAvailablePlayers.find(player => player.playerId === initialSelectedPlayerId);
      if(!selectedPlayer) {
        throw new Error(`Player with id ${initialSelectedPlayerId} not found`);
      }
      setSelectedPlayer(selectedPlayer!);
      setPlayers(filteredAvailablePlayers);
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
      enqueueSnackbar(`Failed to fetch players: ${err.message}`, {variant: 'error'});
    })
  }, [open]);

  const handlePlayerChange = (player: PlayerInfoDto) => {
    setSelectedPlayer(player);
  }

  const handleSave = () => {
    onSave(selectedPlayer);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      {isLoading ? <LinearProgress /> :
        <Box sx={{ width: 400, backgroundColor: 'background.paper', p: 4, mx: 'auto', mt: '10%', borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom>
            Match Statting Settings
          </Typography>
          <FormControl style={{width: '100%'}}>
            <Autocomplete
              options={players}
              getOptionLabel={(option: PlayerInfoDto) => !option ? '' : `${option.firstName} ${option.lastName} (${option.school})`}
              isOptionEqualToValue={(option, value) => option?.playerId === value?.playerId}
              value={selectedPlayer}
              renderInput={(params) => <TextField {...params} label="Player" />}
              onChange={(event, newValue) => handlePlayerChange(newValue!)}
            />
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={onClose} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      }
    </Modal>
  )
}