import {MatchInfoDto, MatchPartnershipInfoDto} from "sandy-shared/dist/types/match-info.type";
import {StattedPointDto} from "sandy-shared/dist/dtos/statted-point.dto";

export const getMatchCurrentPlayerIds = (match: MatchInfoDto): string[] =>
    [match.homePartnership.playerOne.playerId, match.homePartnership.playerTwo.playerId, match.awayPartnership.playerOne.playerId, match.awayPartnership.playerTwo.playerId];

// Practice matches can have more than two partnerships in their history
export const getAllMatchPartnershipIds = (match: MatchInfoDto, pointHistory: StattedPointDto[]): string[] => {
  const matchPoints = pointHistory.filter(point => match.sets.find(set => set.setId === point.setId));
  const partnershipIds = matchPoints.flatMap(point => [point.receivingPartnershipId, point.servingPartnershipId]);
  return Array.from(new Set(partnershipIds));
}

export const getPartnershipPlayerIds = (partnerships: MatchPartnershipInfoDto[]): string[] =>
  partnerships.flatMap(partnership => [partnership.playerOne.playerId, partnership.playerTwo.playerId]);

export const getAllMatchPlayerIds = (match: MatchInfoDto, pointHistory: StattedPointDto[], allPartnerships: MatchPartnershipInfoDto[]): string[] => {
  const matchPartnershipIds = getAllMatchPartnershipIds(match, pointHistory);
  const matchPartnerships = allPartnerships.filter(partnership => matchPartnershipIds.includes(partnership.partnershipId));
  return getPartnershipPlayerIds(matchPartnerships);
}
