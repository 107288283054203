import { AddPlayerDto } from "sandy-shared/dist/dtos/player.dto";
import {MatchInfoDto} from "sandy-shared/src/types/match-info.type";
import {AddMatchDto} from "sandy-shared/dist/types/match-info.type";
import {CompleteSetDto} from "sandy-shared/dist/dtos/match-actions.dto";
import {authenticatedFetch} from "../shared/services/http.service";

export const getMatch = async (matchId: string): Promise<MatchInfoDto> => {
  const result = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/matches/${matchId}`,
    { headers: { 'Content-Type': 'application/json' } },
  ).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to fetch match: ${response.statusText}`);
    }
    return await response.json() as MatchInfoDto;
  });
  return result;
}

export const getMatches = async (): Promise<MatchInfoDto[]> => {
  const result = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/matches`,
    { headers: { 'Content-Type': 'application/json' } },
  ).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to fetch matches: ${response.statusText}`);
    }
    return await response.json() as MatchInfoDto[]
  });
  return result;
}

export const getMatchInfo = async (matchId: string): Promise<MatchInfoDto> => {
  return getMatches().then(matches => matches.find(match => match.matchId === matchId)!);
}

export async function addMatch(match: AddMatchDto): Promise<MatchInfoDto> {
  const createdMatch = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/matches`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(match)
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to add match: ${response.statusText}`);
      }
      return await response.json() as MatchInfoDto
    });
  return createdMatch;
}

export async function updateMatch(matchId: string, updatedMatch: Partial<AddMatchDto>): Promise<MatchInfoDto> {
  const updatedMatchResult = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/matches/${matchId}`,
    {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(updatedMatch)
    }).then(async response => {
    if (!response.ok) {
      throw new Error(`Failed to update match: ${response.statusText}`);
    }
    return await response.json() as MatchInfoDto
  });
  return updatedMatchResult;
}

export async function completeSet(matchId: string, dto: CompleteSetDto): Promise<MatchInfoDto> {
  const result = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/matches/${matchId}/complete-set`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(dto)
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to complete set: ${response.statusText}`);
      }
      return await response.json() as MatchInfoDto
    });
  return result;
}

export async function startNewSet(matchId: string): Promise<MatchInfoDto> {
  const result = await authenticatedFetch(
    `${process.env.REACT_APP_SANDY_API_BASE_URL}/api/v1/matches/${matchId}/start-new-set`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`Failed to start set: ${response.statusText}`);
      }
      return await response.json() as MatchInfoDto
    });
  return result;
}