import {Card, CardContent, CardHeader, Grid, Typography} from '@mui/material';
import React from "react";
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import {PlayerInfo} from "sandy-shared/dist/types/player-info.type";
import { PlayerStatsDto } from 'sandy-shared/dist/dtos/player.dto';

interface PlayerStatsProps {
  playerInfo: PlayerInfo;
  playerStats: PlayerStatsDto;
  isServing: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const PlayerStatsCard: React.FC<PlayerStatsProps> = ({playerInfo, playerStats, isServing, onClick}) => {
  const displayNumber = playerInfo.number ? `#${playerInfo.number} ` : '';
  const displayName = `${playerInfo.firstName} ${playerInfo.lastName}`;
  return (
    <div style={{paddingLeft: '25px', paddingRight: '25px', paddingTop: '10px', paddingBottom: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', textAlign: 'center'}}>
      <Card sx={{ minWidth: 300, borderRadius: '10px', cursor: onClick ? 'pointer': '' }} onClick={onClick}>
        <CardHeader title={`${displayNumber}${displayName}`} style={{color: 'lightgrey', padding: '5px'}}>
        </CardHeader>
        <CardContent style={{padding: '5px'}}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="caption" sx={{ mb: 1.5 }} color="text.secondary">
                Hitting %
              </Typography>
              <Typography variant="h5" component="div">
                {playerStats.firstBallSideout.percentage.toFixed(3)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" sx={{ mb: 1.5 }} color="text.secondary">
                Receptions
              </Typography>
              <Typography variant="h5" component="div">
                {playerStats.firstBallSideout.opportunities}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" sx={{ mb: 1.5 }} color="text.secondary">
                Kills
              </Typography>
              <Typography variant="h5" component="div">
                {playerStats.firstBallSideout.kills}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" sx={{ mb: 1.5 }} color="text.secondary">
                Errors
              </Typography>
              <Typography variant="h5" component="div">
                {playerStats.firstBallSideout.errors}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {isServing && <SportsVolleyballIcon
          fontSize={'large'}
          sx={{ position: 'absolute', bottom: 0, right: 10 }}
      />}
    </div>
  );
};

export default PlayerStatsCard;